import { Box, SvgIcon } from '@mui/material'
import React from 'react'
import { Button, Col, Container, NavLink, Row } from 'react-bootstrap'
import iatalogo from "../../assets/images/IATA.png";
import "./footer.css"
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import AddIcCallTwoToneIcon from '@mui/icons-material/AddIcCallTwoTone';
import WhatsappTwoToneIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';

function Footer() {
    return (
        <>
            <div className="footer" id="footer">
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={3} lg={3} xl={3} className="zerogap footerlogowrp">
                            <Box className="footerimg">
                                <img className='iatalogo' src={iatalogo}></img>
                            
                                <Box className="socialicons">
                                    <NavLink target='_blank' href="https://www.facebook.com/b2btravelagent/"><SvgIcon><FacebookIcon /></SvgIcon></NavLink>
                                    <NavLink to="#"><SvgIcon><TwitterIcon /></SvgIcon></NavLink>
                                    <NavLink to="#"><SvgIcon><YouTubeIcon /></SvgIcon></NavLink>
                                    <NavLink to="#"><SvgIcon><LinkedInIcon /></SvgIcon></NavLink>
                                </Box>
                                
                            </Box>
                        
                        
                    </Col>
                        <Col xs={12} sm={12} md={9} lg={9} xl={9} className="zerogap">
                            <Box className="listlinkitemsbox">
                               
                                <Box className="footeraddress">
                                    Contact Us
                                    <p><a className='whatsappchat' href='https://wa.me/9739499689' target="_blank"><WhatsappTwoToneIcon />&nbsp; Chat On WhatsApp</a></p>
                                    <p className='footerPhoneNo'><AddIcCallTwoToneIcon />&nbsp; 080 41277577</p>
                                    <p><EmailIcon />&nbsp; care@b2btravelagent.com</p>
                                </Box>
                                <Box className="footeraddress">
                                    Address
                                    <p>No. 6, AUM, Millers Tank Bund Rd, Vasanth Nagar, Bengaluru, Karnataka 560052</p>
                                </Box>
                                
                                <Box className="linklistul">
                                    <ul>
                                        <li><NavLink href="about-us" to="about-us">About Us</NavLink></li>
                                        <li><NavLink href="our-services" to="our-services">Our Services</NavLink></li>
                                        <li><NavLink href="privacy-policy" to="privacy-policy">Privacy policy</NavLink></li>
                                        <li><NavLink href="terms-and-condition" to="terms-and-condition">Terms & Condition</NavLink></li>
                                        <li><NavLink href="refund-policy" to="refund-policy">Refund Policy</NavLink></li>

                                        {/* <li><NavLink to="#">link el\ement</NavLink></li>
                                        <li><NavLink to="#">link element</NavLink></li>
                                        <li><NavLink to="#">link element</NavLink></li>
                                        <li><NavLink to="#">link element</NavLink></li>
                                        <li><NavLink to="#">link element</NavLink></li> */}
                                    </ul>
                                </Box>
                                <Box className="footeraddress portal-link-btn">
                                    <NavLink href='sign-up'>Supplier Registration</NavLink>
                                   
                                </Box>
                                
                            </Box>
                        </Col>
                        
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Footer
