import { Button } from '@mui/material'
import React from 'react'
import { Col , Row} from 'react-bootstrap'
import imgnodata from '../../../assets/images/filter.png'
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  notfoundddata: {
    textAlign: 'right',
    alignItems: 'right',
    justifyContent: 'center',
    padding: '50px 0px'
  },
  imgdata: {
    width: 100
  },
  para: {
    fontSize: 19,
    fontWeight: '700'
  },
  minipara: {
    fontSize: 14,
    color: '#808080'
  },
  btnresetfilter: {
    backgroundColor: "#fff !important",
    border: "none !important",
    boxShadow: "none !important",
    padding: "0px !important",
    color:" #1675b8 !important",
    fontSize: "14px !important",
    fontWeight: "600 !important",
    marginTop: "10px !important"
  },
  filtererrwrp: {
    backgroundColor: '#fff'
  },
  errfiltertxt: {
    padding: "20px"
  }
})

export default function NotFindData (props) {
  const classes = useStyles()
  return (
        <div className={classes.filtererrwrp} >
          <Row>
            <Col xs={4} sm={12} md={2} lg={2} xl={2}>
              <div className={classes.notfoundddata}>
                <div className={classes.imgfnd}><img src={imgnodata} className={classes.imgdata}/></div>
              </div>
            </Col>
            <Col xs={8} sm={12} md={10} lg={10} xl={10} className="m-auto">
              <div className={classes.errfiltertxt}>
                <div className={classes.para}>We couldn't find flights to match your filters</div>
                <div className={classes.minipara}>Please reset your filters to see flights</div>
                <Button className={classes.btnresetfilter } variant="contained" color='secondary' onClick = {() => props.handleReset()}>Reset Filter</Button>
              </div>
            </Col>
          </Row>
           
        </div>
  )
}
