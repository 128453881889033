import { Box, Container, Snackbar, SnackbarContent, TextField, FormControlLabel, Checkbox } from '@mui/material'
import React, { useState, useEffect, useCallback } from 'react'
import makeStyles from '@mui/styles/makeStyles';
import { Col, Row, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { fareIndentifire, stops } from '../../../store/flights/actions/flightFilterActions'
import { setAllAirline, setMinMaxValue, bookingSources } from '../../../store/flights/actions/flightSearchActions'
import FlightLoader from '../../skeleton/FlightLoader'
import RoundTripSearchSkelton from '../../skeleton/RoundTripSearchSkelton'
import '../flight-results/roundTripResults.css'
import Airlinesfilter from './filters/Airlinesfilter'
import Fareidentifier from './filters/Fareidentifier'
import Pricerange from './filters/Pricerange'
import Shownet from './filters/Shownet'
import Stops from './filters/Stops'
import NotFindData from './NotFindData'
import Timing from './filters/Timing'
import NotFoundFlight from './NotFoundFlight'
import RoundTripBook from './RoundTripBook'
import RoundTripResults from './RoundTripResults'
import RoundTripResultsMini from './RoundTripResultsMini'
import InfiniteScroll from 'react-infinite-scroll-component'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import CloseIcon from '@mui/icons-material/Close';
import { flightDate } from '../../../core/helper';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SwipeableViews from 'react-swipeable-views';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
    '& label': {
      margin: 0,
      marginBottom: 0
    },
    '& div': {
      margin: 0
    }
  },
  selectbx: {
    padding: 0,
    '&::before': {
      content: '',
      display: 'none'
    },
    '&::after': {
      content: '',
      display: 'none'
    }
  },

  rndsortbox: {
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex',
    alignContent: 'flex-start',
    justifyContent: 'space-between'
  },
  drpsort: {
    fontSize: 12,
    fontWeight: '500',
    textAlign: 'left'
  },

  selectEmpty: {
    marginTop: theme.spacing(0)
  }
}))


const RoundTripSearchResults = () => {
  const classes = useStyles()
  const [showFilterMob, setshowFilterMob] = useState(true)

  const toggleFilter = () =>{
    setshowFilterMob(!showFilterMob)
  }

  const [state, setState] = React.useState({
    age: '',
    name: 'hai'
  })

  const handleChange = event => {
    const name = event.target.name
    setState({
      ...state,
      [name]: event.target.value
    })
  }

  const range = useSelector(state => state.rangeValue)

  const { fsRoundData, fsRoundReqData, isRoundLoading, roundError, fsRoundCount } = useSelector(state => state.flightResult)
  const dispatch = useDispatch()

  const [onwardResults, setOnwardResults] = useState([])
  const [returnResults, setReturnResults] = useState([])
  const [errorMsg, setErrorMsg] = useState(false)

  const [skeltonCount, setSkeltonCount] = useState(10)
  const [onFlight, setOnFlight] = useState(null)
  const [retFlight, setRetFlight] = useState(null)
  const [showWhatsapp, setShowWhatsapp] = useState(false)

  const [filterOnward, setFilterOnward] = useState({
    priceMinRange: 0,
    priceMaxRange: 0,
    showNet: false,
    stops: null,
    arrivalTime: null,
    depatureTime: null,
    fareIdentifire: [],
    airline: [],
    value: null
  })

  const [filterReturn, setFilterReturn] = useState({
    priceMinRange: 0,
    priceMaxRange: 0,
    showNet: false,
    stops: null,
    arrivalTime: null,
    depatureTime: null,
    fareIdentifire: [],
    airline: [],
    value: null
  })

  const [activeOnBg, setactiveOnBg] = useState('on-0')
  const [activeRetBg, setactiveRetBg] = useState('ret-0')
  const [onwardLoadMore, setOnwardLoadMore] = useState(false)
  const [onwardPage, setOnwardPage] = useState(0)
  const [onwardPaginationData, setOnwardPaginationData] = useState([])
  const [returnLoadMore, setReturnLoadMore] = useState(false)
  const [returnPage, setReturnPage] = useState(0)
  const [returnpaginationData, setReturnPaginationData] = useState([])
  const [selectedFlight, setSelectedFlight] = useState([]);
  const [sharedFlightList, setSharedFlightList] = useState([]);
  const [sharedMobileNum, setSharedMobileNum] = useState("");
  const [sharedMobileNumError, setSharedMobileNumError] = useState(null);
  const [onwardToggleSort, setOnwardToggleSort] = useState({
    departure: false,
    duration: false,
    arrival: false,
    price: true,
    type: null,
    airline: false
  })
  const [returnToggleSort, setReturnToggleSort] = useState({
    departure: false,
    duration: false,
    arrival: false,
    price: true,
    type: null,
    airline: false
  })

  // const BookingSourceLength = bookingSources.length+1

  let BookingSourceLength
  if (fsRoundReqData && !fsRoundReqData.specialFare) {
    BookingSourceLength = bookingSources.length + 1
  } else {
    BookingSourceLength = 1
  }

  function groupBy (list, keyGetter) {
    const map = new Map()
    list.forEach((item) => {
      const key = keyGetter(item)
      const collection = map.get(key)
      if (!collection) {
        map.set(key, [item])
      } else {
        collection.push(item)
      }
    })
    return map
  }

  const getMinMaxValue = (fligths) => {
    let min = fsRoundData && fsRoundData[0] && fsRoundData[0][0] && fsRoundData[0][0].FareDetails.b2b_PriceDetails._CustomerBuying
    let max = fsRoundData && fsRoundData[0] && fsRoundData[0][0] && fsRoundData[0][0].FareDetails.b2b_PriceDetails._CustomerBuying

    const airlines = []
    const airMap = []
    const fareMap = []

    {
      fligths.map((value, index) => {
        airlines.push(value.SegmentSummary[0].AirlineDetails.AirlineName)
        const v = value.FareDetails.b2b_PriceDetails._CustomerBuying
        min = (v < min) ? v : min
        max = (v > max) ? v : max
      })
    }

    const grouped = groupBy(fligths, value => value.SegmentSummary[0].AirlineDetails.AirlineName)
    const fareIdentifires = groupBy(fligths, value => value.Attr.Faretype)

    for (const entry of grouped.entries()) {
      let airMinPrice = entry[1][0].FareDetails.b2b_PriceDetails._CustomerBuying

      {
        entry[1].map((value, index) => {
          const v = value.FareDetails.b2b_PriceDetails._CustomerBuying
          airMinPrice = (v < airMinPrice) ? v : airMinPrice
        })
      }

      const obj = {
        count: Object.keys(entry[1]).length,
        price: airMinPrice,
        name: entry[0],
        isChecked: false
      }
      airMap.push(obj)
    }

    for (const fi of fareIdentifires.entries()) {
      const obj = {}

      if (fi[0] === 'REGULAR') {
        obj.color = 'colordefine3'
      } else if (fi[0] === 'TACTICAL') {
        obj.color = 'colordefine6'
      } else if (fi[0] === 'SME') {
        obj.color = 'colordefine4'
      } else if (fi[0] === 'DEAL') {
        obj.color = 'colordefine2'
      } else if (fi[0] === 'REFUNDABLE') {
        obj.color = 'colordefine1'
      } else {
        obj.color = 'colordefine5'
      }

      obj.count = Object.keys(fi[1]).length
      obj.name = fi[0]
      obj.isChecked = false

      fareMap.push(obj)
    }

    dispatch(setMinMaxValue([min, max]))
    dispatch(setAllAirline(airMap))

    dispatch(fareIndentifire(fareMap))
  }

  const onwardStopChange = useCallback(
    (stop) => {
      const flitOn = { filterOnward }
      flitOn.filterOnward.stops = stop
      filterOnwardData()
    }
  )

  const returnStopChange = useCallback(
    (stop) => {
      const flitRe = { filterReturn }
      flitRe.filterReturn.stops = stop
      filterReturnData()
    }
  )

  const onwardChangeDepFrom = useCallback(
    (time) => {
      const flitOn = { filterOnward }
      flitOn.filterOnward.depatureTime = time
      filterOnwardData()
    }
  )

  const returnChangeDepFrom = useCallback(
    (time) => {
      const flitRe = { filterReturn }
      flitRe.filterReturn.depatureTime = time
      filterReturnData()
    }
  )

  const onwardChangeArrAt = useCallback(
    (time) => {
      const flitOn = { filterOnward }
      flitOn.filterOnward.arrivalTime = time
      filterOnwardData()
    }
  )

  const returnChangeArrAt = useCallback(
    (time) => {
      const flitRe = { filterReturn }
      flitRe.filterReturn.arrivalTime = time
      filterReturnData()
    }
  )

  const onPriceRageChange = useCallback(
    (ranges) => {
      const flitOn = { filterOnward }
      const flitRet = { filterReturn }

      flitOn.filterOnward.priceMinRange = ranges[0]
      flitOn.filterOnward.priceMaxRange = ranges[1]

      flitRet.filterReturn.priceMinRange = ranges[0]
      flitRet.filterReturn.priceMaxRange = ranges[1]

      filterOnwardData()
      filterReturnData()
    }
  )

  const handleRangChange1 = useCallback(
    (ranges) => {
      setFilterOnward({
        ...filterOnward,
        priceMinRange: ranges[0],
        priceMaxRange: ranges[1]
      })

      setFilterReturn({
        ...filterReturn,
        priceMinRange: ranges[0],
        priceMaxRange: ranges[1]
      })
    }
  )

  const onShowNetChanges = useCallback(
    (data) => {
      const flitOn = { filterOnward }
      const flitRet = { filterReturn }

      flitOn.filterOnward.showNet = data
      flitRet.filterReturn.showNet = data

      filterOnwardData()
      filterReturnData()
    }
  )
  const onAirlinesfilterChange = useCallback(
    (data, status) => {
      const flitOn = { filterOnward }
      const flitRet = { filterReturn }

      if (status) {
        flitOn.filterOnward.airline.push(data)
        flitRet.filterReturn.airline.push(data)
      } else {
        flitOn.filterOnward.airline.splice(flitOn.filterOnward.airline.indexOf(data), 1)
        flitRet.filterReturn.airline.splice(flitRet.filterReturn.airline.indexOf(data), 1)
      }

      filterOnwardData()
      filterReturnData()
    }
  )

  const onFareIdentifireChange = useCallback(
    (datas, status) => {
      const flitOn = { ...filterOnward }
      const flitRet = { ...filterReturn }

      if (flitOn.fareIdentifire.includes(datas)) {
        flitOn.fareIdentifire.splice(flitOn.fareIdentifire.indexOf(datas), 1)
      } else {
        flitOn.fareIdentifire.push(datas)
      }

      if (flitRet.fareIdentifire.includes(datas)) {
        flitRet.fareIdentifire.splice(flitRet.fareIdentifire.indexOf(datas), 1)
      } else {
        flitRet.fareIdentifire.push(datas)
      }

      filterOnwardData()
      filterReturnData()
    }
  )

  const clearAirline = useCallback(
    () => {
      const flitOn = { filterOnward }
      const flitRet = { filterReturn }
      flitOn.filterOnward.airline = []
      flitRet.filterReturn.airline = []

      filterOnwardData()
      filterReturnData()
    }

  )

  function groupFlights (array, f) {
    const groups = {}
    array.forEach(function (o) {
      const group = JSON.stringify(f(o))
      groups[group] = groups[group] || []
      groups[group].push(o)
    })
    return Object.keys(groups).map(function (group) {
      return groups[group]
    })
  }

  const returnFlightSelect = (data, highlight) => {
    setactiveRetBg(data.Token)
    if (onFlight) {
      const date1 = new Date(onFlight.SegmentSummary[0].DestinationDetails.DateTime)
      const date2 = new Date(data.SegmentSummary[0].OriginDetails.DateTime)

      const seconds = Math.round((date2.getTime() - date1.getTime()) / 1000)

      const hours = Math.round(seconds / 60 / 60)

      if (hours < 4) {
        setErrorMsg(true)
        setRetFlight(data)
      } else {
        setRetFlight(data)
      }
    }
  }

  const onwardFlightSelect = (data, highlight) => {
    setactiveOnBg(data.Token)

    if (retFlight) {
      const date1 = new Date(data.SegmentSummary[0].DestinationDetails.DateTime)
      const date2 = new Date(retFlight.SegmentSummary[0].OriginDetails.DateTime)

      const seconds = Math.round((date2.getTime() - date1.getTime()) / 1000)

      const hours = Math.round(seconds / 60 / 60)

      if (hours < 4) {
        setErrorMsg(true)
        setOnFlight(data)
      } else {
        setOnFlight(data)
      }
    }
  }

  const timeSplitDash = (time) => {
    const arr = time.split('-')
    const data1 = arr[0] * 3600
    const data2 = arr[1] * 3600

    return [data1, data2]
  }

  const timeSplitColon = (time) => {
    const arr = time.split(':')
    const data = (arr[0] * 3600) + (arr[1] * 60)
    return data
  }

  const filterOnwardData = () => {
    if (fsRoundData) {
      let result = null

      result = fsRoundData && fsRoundData[0] && fsRoundData[0].filter((value) => {
        if (filterOnward.priceMinRange <= value.FareDetails.b2b_PriceDetails._CustomerBuying && value.FareDetails.b2b_PriceDetails._CustomerBuying <= filterOnward.priceMaxRange) {
          return value
        }
      }
      )

      if (filterOnward.stops === null) {
        result = result
      } else {
        result = result.filter((value) => {
          if (filterOnward.stops === null) {
            return value
          } else {
            if (filterOnward.stops === 3) {
              return filterOnward.stops <= value.SegmentSummary[0].TotalStops
            } else {
              return value.SegmentSummary[0].TotalStops === filterOnward.stops
            }
          }
        })
      }

      if (filterOnward.depatureTime === null) {
        result = result
      } else {
        result = result.filter((value) => {
          const d = timeSplitDash(filterOnward.depatureTime)
          const t = timeSplitColon(value.SegmentSummary[0].OriginDetails._DateTime)
          if (d[0] <= t && d[1] >= t) {
            return value
          }
        })
      }

      if (filterOnward.arrivalTime === null) {
        result = result
      } else {
        result = result.filter((value) => {
          const d = timeSplitDash(filterOnward.arrivalTime)
          const t = timeSplitColon(value.SegmentSummary[0].DestinationDetails._DateTime)
          if (d[0] <= t && d[1] >= t) {
            return value
          }
        })
      }

      if (filterOnward.airline.length === 0) {
        result = result
      } else {
        result = result.filter((value) => {
          if (filterOnward.airline.includes(value.SegmentSummary[0].AirlineDetails.AirlineName)) {
            return value
          }
        })
      }

      if (filterOnward.fareIdentifire.length === 0) {
        result = result
      } else {
        result = result.filter((value) => {
          if (filterOnward.fareIdentifire.includes(value.Attr.Faretype)) {
            return value
          }
        })
      }

      let results = groupFlights(result, function (value) {
        return [
          value.SegmentSummary[0].AirlineDetails.FlightNumber,
          value.SegmentSummary[0].AirlineDetails.AirlineCode,
          value.SegmentSummary[0].TotalStops,
          value.SegmentSummary[0].TotalDuaration,
          value.SegmentSummary[0].OriginDetails.DateTime,
          value.SegmentSummary[0].DestinationDetails.DateTime
        ]
      })

      results = results ? sortByPrice(results) : null
      window.scrollTo(0, 0)
      if (document.getElementById('onward')) {
        const myDiv = document.getElementById('onward')
        myDiv.scrollTop = 0
      }
      if (results)
      {
        sliceArray(results, 'onward')
        setOnwardLoadMore(true)
        setOnwardPage(0)
        setOnwardResults(results)
      }
      
    }
  }

  const filterReturnData = () => {
    if (fsRoundData) {
      let result = null

      result = fsRoundData && fsRoundData[1] && fsRoundData[1].filter((value) => {
        if (filterReturn.priceMinRange <= value.FareDetails.b2b_PriceDetails._CustomerBuying && value.FareDetails.b2b_PriceDetails._CustomerBuying <= filterReturn.priceMaxRange) {
          return value
        }
      }
      )

      if (filterReturn.stops === null) {
        result = result
      } else {
        result = result.filter((value) => {
          if (filterReturn.stops === null) {
            return value
          } else {
            if (filterReturn.stops === 3) {
              return filterReturn.stops <= value.SegmentSummary[0].TotalStops
            } else {
              return value.SegmentSummary[0].TotalStops === filterReturn.stops
            }
          }
        })
      }

      if (filterReturn.depatureTime === null) {
        result = result
      } else {
        result = result.filter((value) => {
          const d = timeSplitDash(filterReturn.depatureTime)
          const t = timeSplitColon(value.SegmentSummary[0].OriginDetails._DateTime)
          if (d[0] <= t && d[1] >= t) {
            return value
          }
        })
      }

      if (filterReturn.arrivalTime === null) {
        result = result
      } else {
        result = result.filter((value) => {
          const d = timeSplitDash(filterReturn.arrivalTime)
          const t = timeSplitColon(value.SegmentSummary[0].DestinationDetails._DateTime)
          if (d[0] <= t && d[1] >= t) {
            return value
          }
        })
      }

      if (filterReturn.airline.length === 0) {
        result = result
      } else {
        result = result.filter((value) => {
          if (filterReturn.airline.includes(value.SegmentSummary[0].AirlineDetails.AirlineName)) {
            return value
          }
        })
      }

      if (filterReturn.fareIdentifire.length === 0) {
        result = result
      } else {
        result = result.filter((value) => {
          if (filterReturn.fareIdentifire.includes(value.Attr.Faretype)) {
            return value
          }
        })
      }

      let results = groupFlights(result, function (value) {
        return [
          value.SegmentSummary[0].AirlineDetails.FlightNumber,
          value.SegmentSummary[0].AirlineDetails.AirlineCode,
          value.SegmentSummary[0].TotalStops,
          value.SegmentSummary[0].TotalDuaration,
          value.SegmentSummary[0].OriginDetails.DateTime,
          value.SegmentSummary[0].DestinationDetails.DateTime
        ]
      })

      results = sortByPrice(results)
      window.scrollTo(0, 0)
      if (document.getElementById('return')) {
        const myDiv = document.getElementById('return')
        myDiv.scrollTop = 0
      }
      sliceArray(results, 'return')
      setReturnLoadMore(true)
      setReturnPage(0)
      setReturnResults(results)
    }
  }

  const handleReset = () => {
    const filt = { filterOnward }
    const filtRet = { filterReturn }

    filt.filterOnward.priceMinRange = range.minValue
    filt.filterOnward.priceMaxRange = range.maxValue
    filt.filterOnward.showNet = false
    filt.filterOnward.stops = null
    filt.filterOnward.arrivalTime = null
    filt.filterOnward.depatureTime = null
    filt.filterOnward.fareIdentifire = []
    filt.filterOnward.airline = []

    filtRet.filterReturn.priceMinRange = range.minValue
    filtRet.filterReturn.priceMaxRange = range.maxValue
    filtRet.filterReturn.showNet = false
    filtRet.filterReturn.stops = null
    filtRet.filterReturn.arrivalTime = null
    filtRet.filterReturn.depatureTime = null
    filtRet.filterReturn.fareIdentifire = []
    filtRet.filterReturn.airline = []
    dispatch(setMinMaxValue([range.minValue, range.maxValue]))
    onShowNetChanges(false)
    dispatch(stops(null))
    filterOnwardData()
    filterReturnData()
  }

  const handleCloseMsg = () => {
    setErrorMsg(false)
  }

  useEffect(() => {
    setOnwardResults([])
    setReturnResults([])
    setOnwardPaginationData([])
    if (fsRoundData) {
      let a = []
      let b = []
      let c = []
      let onwardData
      let returnData

       a = fsRoundData && fsRoundData[0] ? fsRoundData[0] : a
       b = fsRoundData && fsRoundData[1] ? fsRoundData[1] : b
       c = [...a, ...b]

      getMinMaxValue(c)

      
      if(fsRoundData && fsRoundData[0])
      {
          onwardData = groupFlights(fsRoundData[0], function (value) {
          return [
            value.SegmentSummary[0].AirlineDetails.FlightNumber,
            value.SegmentSummary[0].AirlineDetails.AirlineCode,
            value.SegmentSummary[0].TotalStops,
            value.SegmentSummary[0].TotalDuaration,
            value.SegmentSummary[0].OriginDetails.DateTime,
            value.SegmentSummary[0].DestinationDetails.DateTime
          ]
        })
      }
      
      if(fsRoundData && fsRoundData[1])
      {
         returnData = groupFlights(fsRoundData[1], function (value) {
          return [
            value.SegmentSummary[0].AirlineDetails.FlightNumber,
            value.SegmentSummary[0].AirlineDetails.AirlineCode,
            value.SegmentSummary[0].TotalStops,
            value.SegmentSummary[0].TotalDuaration,
            value.SegmentSummary[0].OriginDetails.DateTime,
            value.SegmentSummary[0].DestinationDetails.DateTime
          ]
        })
      }
      
      if (onwardData && onwardData.length > 0){
        onwardData = sortByPrice(onwardData)
        setOnFlight(onwardData[0][0])
        setactiveOnBg(onwardData[0][0].Token)
        setOnwardResults(onwardData)
        sliceArray(onwardData, 'onward')
      }

      if(returnData && returnData.length > 0){
        returnData = returnData ? sortByPrice(returnData) : null
        setRetFlight(returnData[0][0])
        setactiveRetBg(returnData[0][0].Token)
        setReturnResults(returnData)
        sliceArray(returnData, 'return')
      }
     
    }

    if (fsRoundCount === BookingSourceLength) {
      setOnwardLoadMore(true)
      setReturnLoadMore(true)
    }
  }, [fsRoundData, fsRoundCount])

  // function to sort array by price
  const sortByPrice = (resultss) => {
    // sorting price inside grouping
    for (let i = 0; i < resultss.length; i++) {
      resultss[i].sort(function (a, b) { return a.FareDetails.b2b_PriceDetails._CustomerBuying - b.FareDetails.b2b_PriceDetails._CustomerBuying })
    }
    // sorting price inside sections
    resultss.sort(function (a, b) { return a[0].FareDetails.b2b_PriceDetails._CustomerBuying - b[0].FareDetails.b2b_PriceDetails._CustomerBuying })

    return resultss
  }

  const sliceArray = (resultss, type) => {
    const startIndex = 0
    const endIndex = 20
    const slicedRecords = resultss.slice(startIndex, endIndex)
    if (type === 'onward') {
      setOnwardPaginationData(slicedRecords)
    } else {
      setReturnPaginationData(slicedRecords)
    }
  }

  const handleObserver = () => {
    setTimeout(() => {
      setOnwardPage((page) => page + 1)
    }, 500)
  }
  const handleReturnObserver = () => {
    setTimeout(() => {
      setReturnPage((page) => page + 1)
    }, 500)
  }

  useEffect(() => {
    if (onwardPage > 0 && fsRoundCount === BookingSourceLength) {
      const dataLimit = 20
      const startIndex = (onwardPage * dataLimit)
      const endIndex = (startIndex + dataLimit)
      const slicedRecords = onwardResults.slice(startIndex, endIndex)
      const data = [...onwardPaginationData, ...slicedRecords]
      setOnwardPaginationData(data)
      if (data.length === onwardResults.length) {
        setOnwardLoadMore(false)
      }
    }
  }, [onwardPage])

  useEffect(() => {
    if (returnPage > 0 && fsRoundCount === BookingSourceLength) {
      const dataLimit = 20
      const startIndex = (returnPage * dataLimit)
      const endIndex = (startIndex + dataLimit)
      const slicedRecords = returnResults.slice(startIndex, endIndex)
      const data = [...returnpaginationData, ...slicedRecords]
      setReturnPaginationData(data)
      if (data.length === returnResults.length) {
        setReturnLoadMore(false)
      }
    }
  }, [returnPage])

  useEffect(() => {
    if (onwardToggleSort.type) {
      sortByAsceDescOnward(onwardToggleSort.type)
    }
  }, [onwardToggleSort])

  useEffect(() => {
    if (returnToggleSort.type) {
      sortByAsceDescRetun(returnToggleSort.type)
    }
  }, [returnToggleSort])

  const sortByAsceDescOnward = (type) => {
    const result = [...onwardResults]
    if (type === 'durationHigh' || type === 'durationLow') {
      if (onwardToggleSort.duration) {
        result.sort(function (a, b) {
          const from = a[0].SegmentSummary[0].TotalDuaration.split(/[hm]/)
          const to = b[0].SegmentSummary[0].TotalDuaration.split(/[hm]/)
          return (from[0] * 60 * 60 + from[1] * 60) * 1000 - (to[0] * 60 * 60 + to[1] * 60) * 1000
        })
      } else {
        result.sort(function (a, b) {
          const from = a[0].SegmentSummary[0].TotalDuaration.split(/[hm]/)
          const to = b[0].SegmentSummary[0].TotalDuaration.split(/[hm]/)
          return (to[0] * 60 * 60 + to[1] * 60) * 1000 - (from[0] * 60 * 60 + from[1] * 60) * 1000
        })
      }
    } else if (type === 'departureHigh' || type === 'departureLow') {
      // if (onwardToggleSort.departure) {
      //   result.sort(function (a, b) { return a[0].SegmentSummary[0].OriginDetails.FDTV - b[0].SegmentSummary[0].OriginDetails.FDTV })
      // } else {
      //   result.sort(function (a, b) { return b[0].SegmentSummary[0].OriginDetails.FDTV - a[0].SegmentSummary[0].OriginDetails.FDTV })
      // }
      if (onwardToggleSort.departure) {
        result.sort(function (a, b) { return Math.round(new Date(a[0].SegmentSummary[0].OriginDetails.DateTime).getTime()/1000) - Math.round(new Date(b[0].SegmentSummary[0].OriginDetails.DateTime).getTime()/1000) })
      } else {
        result.sort(function (a, b) { return Math.round(new Date(b[0].SegmentSummary[0].OriginDetails.DateTime).getTime()/1000) - Math.round(new Date(a[0].SegmentSummary[0].OriginDetails.DateTime).getTime()/1000) })
      }
    } else if (type === 'arrivalHigh' || type === 'arrivalLow') {
      // if (onwardToggleSort.arrival) {
      //   result.sort(function (a, b) { return a[0].SegmentSummary[0].DestinationDetails.FATV - b[0].SegmentSummary[0].DestinationDetails.FATV })
      // } else {
      //   result.sort(function (a, b) { return b[0].SegmentSummary[0].DestinationDetails.FATV - a[0].SegmentSummary[0].DestinationDetails.FATV })
      // }
      if (onwardToggleSort.arrival) {
        result.sort(function (a, b) { return Math.round(new Date(a[0].SegmentSummary[0].DestinationDetails.DateTime).getTime()/1000) - Math.round(new Date(b[0].SegmentSummary[0].DestinationDetails.DateTime).getTime()/1000) })
      } else {
        result.sort(function (a, b) { return Math.round(new Date(b[0].SegmentSummary[0].DestinationDetails.DateTime).getTime()/1000) - Math.round(new Date(a[0].SegmentSummary[0].DestinationDetails.DateTime).getTime()/1000) })
      }
    } else if (type === 'priceHigh' || type === 'priceLow') {
      if (onwardToggleSort.price) {
        result.sort(function (a, b) { return a[0].FareDetails.b2b_PriceDetails._CustomerBuying - b[0].FareDetails.b2b_PriceDetails._CustomerBuying })
      } else {
        result.sort(function (a, b) { return b[0].FareDetails.b2b_PriceDetails._CustomerBuying - a[0].FareDetails.b2b_PriceDetails._CustomerBuying })
      }
    } else if (type === 'airlineHigh' || type === 'airlineLow') {
      if (onwardToggleSort.airline) {
        result.sort(function (a, b) {
          return a[0].SegmentSummary[0].AirlineDetails.AirlineName.localeCompare(b[0].SegmentSummary[0].AirlineDetails.AirlineName)
        })
      } else {
        result.sort(function (a, b) {
          return b[0].SegmentSummary[0].AirlineDetails.AirlineName.localeCompare(a[0].SegmentSummary[0].AirlineDetails.AirlineName)
        })
      }
    }

    window.scrollTo(0, 0)
    setOnwardResults(result)
    sliceArray(result, 'onward')
    setOnwardLoadMore(true)
    setOnwardPage(0)
  }

  const sortByAsceDescRetun = (type) => {
    const result = [...returnResults]
    if (type === 'durationHigh' || type === 'durationLow') {
      if (returnToggleSort.duration) {
        result.sort(function (a, b) {
          const from = a[0].SegmentSummary[0].TotalDuaration.split(/[hm]/)
          const to = b[0].SegmentSummary[0].TotalDuaration.split(/[hm]/)
          return (from[0] * 60 * 60 + from[1] * 60) * 1000 - (to[0] * 60 * 60 + to[1] * 60) * 1000
        })
      } else {
        result.sort(function (a, b) {
          const from = a[0].SegmentSummary[0].TotalDuaration.split(/[hm]/)
          const to = b[0].SegmentSummary[0].TotalDuaration.split(/[hm]/)
          return (to[0] * 60 * 60 + to[1] * 60) * 1000 - (from[0] * 60 * 60 + from[1] * 60) * 1000
        })
      }
    } else if (type === 'departureHigh' || type === 'departureLow') {
      // if (returnToggleSort.departure) {
      //   result.sort(function (a, b) { return a[0].SegmentSummary[0].OriginDetails.FDTV - b[0].SegmentSummary[0].OriginDetails.FDTV })
      // } else {
      //   result.sort(function (a, b) { return b[0].SegmentSummary[0].OriginDetails.FDTV - a[0].SegmentSummary[0].OriginDetails.FDTV })
      // }
      if (returnToggleSort.departure) {
        result.sort(function (a, b) { return Math.round(new Date(a[0].SegmentSummary[0].OriginDetails.DateTime).getTime()/1000) - Math.round(new Date(b[0].SegmentSummary[0].OriginDetails.DateTime).getTime()/1000) })
      } else {
        result.sort(function (a, b) { return Math.round(new Date(b[0].SegmentSummary[0].OriginDetails.DateTime).getTime()/1000) - Math.round(new Date(a[0].SegmentSummary[0].OriginDetails.DateTime).getTime()/1000) })
      }
    } else if (type === 'arrivalHigh' || type === 'arrivalLow') {
      // if (returnToggleSort.arrival) {
      //   result.sort(function (a, b) { return a[0].SegmentSummary[0].DestinationDetails.FATV - b[0].SegmentSummary[0].DestinationDetails.FATV })
      // } else {
      //   result.sort(function (a, b) { return b[0].SegmentSummary[0].DestinationDetails.FATV - a[0].SegmentSummary[0].DestinationDetails.FATV })
      // }
      if (returnToggleSort.arrival) {
        result.sort(function (a, b) { return Math.round(new Date(a[0].SegmentSummary[0].DestinationDetails.DateTime).getTime()/1000) - Math.round(new Date(b[0].SegmentSummary[0].DestinationDetails.DateTime).getTime()/1000) })
      } else {
        result.sort(function (a, b) { return Math.round(new Date(b[0].SegmentSummary[0].DestinationDetails.DateTime).getTime()/1000) - Math.round(new Date(a[0].SegmentSummary[0].DestinationDetails.DateTime).getTime()/1000) })
      }
    } else if (type === 'priceHigh' || type === 'priceLow') {
      if (returnToggleSort.price) {
        result.sort(function (a, b) { return a[0].FareDetails.b2b_PriceDetails._CustomerBuying - b[0].FareDetails.b2b_PriceDetails._CustomerBuying })
      } else {
        result.sort(function (a, b) { return b[0].FareDetails.b2b_PriceDetails._CustomerBuying - a[0].FareDetails.b2b_PriceDetails._CustomerBuying })
      }
    } else if (type === 'airlineHigh' || type === 'airlineLow') {
      if (returnToggleSort.airline) {
        result.sort(function (a, b) {
          return a[0].SegmentSummary[0].AirlineDetails.AirlineName.localeCompare(b[0].SegmentSummary[0].AirlineDetails.AirlineName)
        })
      } else {
        result.sort(function (a, b) {
          return b[0].SegmentSummary[0].AirlineDetails.AirlineName.localeCompare(a[0].SegmentSummary[0].AirlineDetails.AirlineName)
        })
      }
    }

    window.scrollTo(0, 0)
    setReturnResults(result)
    sliceArray(result, 'return')
    setReturnLoadMore(true)
    setReturnPage(0)
  }

  const toggleSortFnc = (event, journeyType) => {
    const obj = {
      departure: false,
      arrival: false,
      duration: false,
      price: false,
      type: event.target.value,
      airline: false
    }
    if (journeyType == 'onward') {
      if (event.target.value === 'departureHigh') {
        obj.departure = false
      } else if (event.target.value === 'departureLow') {
        obj.departure = true
      } else if (event.target.value === 'arrivalHigh') {
        obj.arrival = false
      } else if (event.target.value === 'arrivalLow') {
        obj.arrival = true
      } else if (event.target.value === 'durationHigh') {
        obj.duration = false
      } else if (event.target.value === 'durationLow') {
        obj.duration = true
      } else if (event.target.value === 'priceHigh') {
        obj.price = false
      } else if (event.target.value === 'priceLow') {
        obj.price = true
      } else if (event.target.value === 'airlineHigh') {
        obj.airline = false
      } else if (event.target.value === 'airlineLow') {
        obj.airline = true
      }
      setOnwardToggleSort(obj)
    } else {
      if (event.target.value === 'departureHigh') {
        obj.departure = false
      } else if (event.target.value === 'departureLow') {
        obj.departure = true
      } else if (event.target.value === 'arrivalHigh') {
        obj.arrival = false
      } else if (event.target.value === 'arrivalLow') {
        obj.arrival = true
      } else if (event.target.value === 'durationHigh') {
        obj.duration = false
      } else if (event.target.value === 'durationLow') {
        obj.duration = true
      } else if (event.target.value === 'priceHigh') {
        obj.price = false
      } else if (event.target.value === 'priceLow') {
        obj.price = true
      } else if (event.target.value === 'airlineHigh') {
        obj.airline = false
      } else if (event.target.value === 'airlineLow') {
        obj.airline = true
      }
      setReturnToggleSort(obj)
    }
  }

  const onToggleSelectedFlight= (item, origin, destination, attr, price, data, type)=> {
    let id = origin !== undefined ? data.ProvabAuthKey : item;
    if(selectedFlight.length > 0 && selectedFlight.some(item => item === id)){
      let newSelectedItem = selectedFlight.filter(item => item !== id);
      let newSharedFlightList = sharedFlightList.filter(item=> item.provabAuthKey !== id);
      setSelectedFlight(newSelectedItem);
      setSharedFlightList(newSharedFlightList);
    }
    else{
      let newSelectedItem = [...selectedFlight, id];
      setSelectedFlight(newSelectedItem);
      let dataObj = {"id": item.AirlineDetails.FlightNumber,
                  "flightCode": item.AirlineDetails.AirlineCode,
                  "flightName": item.AirlineDetails.AirlineName,
                  "flightNumber": item.AirlineDetails.FlightNumber,
                  "from_city": origin.CityName,
                  "to_city": destination.CityName,
                  "from_city_time": origin._DateTime,
                  "to_city_time": destination._DateTime,
                  "from_city_date": origin._Date,
                  "to_city_date": destination._Date,
                  "from_city_year": origin.DateTime,
                  "to_city_year": destination.DateTime,
                  "total_duration": item.TotalDuaration,
                  "total_stops": item.TotalStops,
                  "segment_details": data.SegmentDetails[0],
                  "fare_type": attr.Faretype,
                  "price": price,
                  "remarks": null,
                  "provabAuthKey": data.ProvabAuthKey,
                  "type": type}
        setSharedFlightList([...sharedFlightList, dataObj]);
    }
  }

  const handleInputChange = (e, index, value) => {
    let sharedFlightListArray = JSON.parse(JSON.stringify(sharedFlightList));
    // let objIndex = sharedFlightList.findIndex((obj => obj.id === id));
    // sharedFlightListArray[index].price = e.target.value;
    // setSharedFlightList(sharedFlightListArray);
    if (value === "price") {
      sharedFlightListArray[index].price = e.target.value;
      setSharedFlightList(sharedFlightListArray);
    }
    else {
      sharedFlightListArray[index].remarks = e.target.value;
      setSharedFlightList(sharedFlightListArray);
    }
  }
  const handleSharedMobileNum = (e) => {
    setSharedMobileNumError(null);
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    if (onlyNums.length < 10) {
      setSharedMobileNum(onlyNums);
    } else if (onlyNums.length === 10) {
      const number = onlyNums;
      setSharedMobileNum(number);
    }
  }

  const onShareByWhatsApp= ()=> {
    if(sharedMobileNum === null){
      setSharedMobileNumError("Please enter mobile number");
      return;
    }
    else if(sharedMobileNum === ""){
      setSharedMobileNumError("Please enter mobile number");
      return;
    }
    else if(sharedMobileNum && sharedMobileNum.length < 10){
      setSharedMobileNumError("Please enter 10 digit mobile number");
      return;
    }
    else{
    let url = `https://api.whatsapp.com/send/?phone=91${sharedMobileNum}&text= ${sharedFlightList.map(item => {return(
      `*${item.flightName}*(${item.flightCode+ "-" + item.flightNumber}): ${item.type}%0A*${item.from_city+ "-"+ item.to_city}*%0A*Departure:* ${item.from_city_time + ", "+ flightDate(item.from_city_date) + " " + new Date(item.from_city_year).getFullYear() + ", " + new Date(item.from_city_year).toLocaleString('en-us', {weekday:'short'}) + "%0A*Arrival:* "+ item.to_city_time + ", "+ flightDate(item.to_city_date) + " " + new Date(item.to_city_year).getFullYear() + ", " + new Date(item.to_city_year).toLocaleString('en-us', {weekday:'short'})}%0A*Duration:* ${item.total_duration}%0A*Faretype:* ${item.fare_type}%0A*${item.price !== 0 ? `Price:* ${item.price} (For ${fsRoundReqData.child + fsRoundReqData.adult + fsRoundReqData.infant} Traveller(s))`: ""}%0A${item.remarks !== null ? `*Remarks:* ${item.remarks}` : ''}%0A%0A`  
    )}).join('')}*Note* :  All airfares are subject to availability at time of booking and are not guaranteed until purchased and the e-tickets have been issued.&app_absent=0`
    window.open(url, "_blank")
    }
  }

  const copy = () => {
    let a=  `${sharedFlightList.map(item => {return(
        `${item.flightName}(${item.flightCode+ "-" + item.flightNumber}): ${item.type}\n${item.from_city+ "-"+ item.to_city}\n(${item.from_city_time + ", "+ flightDate(item.from_city_date) + ", " + new Date(item.from_city_year).getFullYear() + " - "+ item.to_city_time + ", "+ flightDate(item.to_city_date) + ", " + new Date(item.to_city_year).getFullYear()})\nDuration: ${item.total_duration}\nFaretype: ${item.fare_type}\n${item.price !== 0 ? `Price: ${item.price}`: ""}\n${item.remarks !== null ? `Remarks: ${item.remarks}` : ''}\n\n\n`
      )}).join('')}`
      navigator.clipboard.writeText(a)
    }
  const copyTableFormat = () => {
    var urlField = document.querySelector('#tableDataRound');
    // create a Range object
    var selection = window.getSelection();
    var range = document.createRange();
    range.selectNodeContents(urlField);
    selection.removeAllRanges();
    selection.addRange(range);

    // execute 'copy', can't 'cut' in this case
    document.execCommand('copy');
  }
  

   // console.log("fsRoundReqData",fsRoundReqData)

  return (
        <>

            <Snackbar

                autoHideDuration={5000}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
                open={errorMsg}
                onClose={handleCloseMsg}
                message="The onward and return flights are overlapping with each other. Please change one of these selections"
                key={{
                  vertical: 'top',
                  horizontal: 'center'
                }}

            >

                <SnackbarContent style={{
                  backgroundColor: 'orange'
                }}
                    message={<span id="client-snackbar">The onward and return flights are overlapping with each other. Please change one of these selections</span>}
                />

            </Snackbar>

            <Container fluid="true" className="flightresultcontainer">
                <Row className="margin-bottomitem">
                    <Col xs={12} sm={12} md={3} lg={3} className="flitercolumstick filter-mob colspace" style={{ display: !showFilterMob ? "block" : "none" }}>
                        <Box className="filter-content">
                            <div className="tlt-filter-header">
                              <div className="tltrange-filter">Filter Search</div>
                              <div className="tltrangereset" onClick={handleReset}>Reset All</div>
                            </div>
                            <Airlinesfilter handleChange={onAirlinesfilterChange} airlineClear={clearAirline} isSelected={filterOnward.airline} />
                            <Pricerange handleRangChange={onPriceRageChange} onPrice={handleRangChange1} />
                            <div className="rtnbox">
                                <div className='tripheading'>Onward Journey</div>
                                <div className="separatebox">
                                    <Stops handleChange={onwardStopChange} isStops={filterOnward.stops} />
                                    <Timing title="Departure From " source={fsRoundReqData?.from} handleChange={onwardChangeDepFrom} isSelected={filterOnward.depatureTime} />
                                    <Timing title="Arrival At " source={fsRoundReqData?.to} handleChange={onwardChangeArrAt} isSelected={filterOnward.arrivalTime} />
                                </div>
                            </div>

                            <div className="rtnbox">
                                <div className='tripheading'>Return Journey</div>
                                <div className="separatebox">
                                    <Stops handleChange={returnStopChange} isStops={filterReturn.stops} />
                                    <Timing title="Departure From " source={fsRoundReqData?.to} handleChange={returnChangeDepFrom} isSelected={filterReturn.depatureTime} />
                                    <Timing title="Arrival At " source={fsRoundReqData?.from} handleChange={returnChangeArrAt} isSelected={filterReturn.arrivalTime} />
                                </div>
                            </div>

                            <Fareidentifier handleChange={onFareIdentifireChange} isSelected={filterOnward.fareIdentifire} />
                        </Box>
                    </Col>
                    <Col xs={12} sm={12} md={9} lg={9} className="white-bg-xs roundtripresult-des">
                        {fsRoundCount !== BookingSourceLength && (
                            <div>
                                <FlightLoader />
                            </div>

                        )}
                        {isRoundLoading && (
                            <div>
                                {/* <FlightLoader /> */}
                                {[...Array(skeltonCount)].map((e, i) => <RoundTripSearchSkelton key={i} />)}
                            </div>
                        )}

                          <Row>
                            {fsRoundData && (
                                <>
                                  <Col xs={6} sm={6} md={12} lg={12} className="rdttopinfobar">
                                    <Box className="setbtnprevnext">
                                      <Box className='whatsapp-share-text'>
                                        <FormControlLabel className="check-whatsapp"
                                          control={<Checkbox name="checkedA" checked={showWhatsapp} onChange={()=> setShowWhatsapp(!showWhatsapp)} />}
                                          label=""
                                        />
                                        <span className='whatsapp-share-icon'><WhatsAppIcon /> WhatsApp</span>
                                      </Box>
                                      <Shownet onShowNetChange={onShowNetChanges} isChecked={filterOnward.showNet} /> 
                                    </Box>
                                  </Col>
                                    {onwardResults.length > 0 ? (
                                        
                                        <Col xs={6} sm={6} md={6} lg={6} className="flitercolumstick-left">
                                            <Box className="scrollingdiv" id="onward">
                                                <Box className="filter-msg-output">found {Object.keys(onwardResults).length} flights from {fsRoundReqData.from} to {fsRoundReqData.to}</Box>

                                                <Row className="viewroundresultbooking addonbackground_round">

                                                    <Col xs={6} sm={6} md={6} lg={6}>
                                                        <Box className="tltrndheader text-left">Select Onward Flights</Box>
                                                    </Col>
                                                    <Col xs={6} sm={6} md={6} lg={6}>

                                                        <Box className={classes.rndsortbox}>
                                                            <Box className={classes.drpsort}>Sort By</Box>
                                                            <Box className={classes.drpsort}>
                                                                <FormControl variant="standard" className={classes.formControl}>
                                                                    <Select
                                                                        className={[classes.selectbx, "selectsortby"]}
                                                                        native
                                                                        value={onwardToggleSort.value}
                                                                        onChange={(e) => toggleSortFnc(e, 'onward')}
                                                                        inputProps={{
                                                                          name: 'age',
                                                                          id: 'age-native-simple'
                                                                        }}
                                                                    >
                                                                        {
                                                                            sortObj.map((item, index) => {
                                                                              return <option key={index} className={classes.itemopt} value={item.value}>{item.name}</option>
                                                                            })
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                            </Box>
                                                        </Box>
                                                    </Col>

                                                </Row>

                                                <InfiniteScroll
                                                    dataLength={onwardPage * 20}
                                                    next={handleObserver}
                                                    scrollableTarget={'onward'}
                                                    hasMore={onwardLoadMore}
                                                    loader={<h4>Loading...</h4>}>

                                                    {onwardPaginationData.map((value, index) => (
                                                        <div>
                                                            <RoundTripResults 
                                                              results={value} 
                                                              key={index} 
                                                              keyValue={'on-' + index} 
                                                              onwardSelected={onwardFlightSelect} 
                                                              type="onward" 
                                                              showNetFare={filterOnward.showNet}
                                                              showWhatsapp={showWhatsapp} 
                                                              onActiveBg={activeOnBg} 
                                                              onToggleSelectedFlight={onToggleSelectedFlight}
                                                              selectedFlight={selectedFlight}
                                                              paginationData={onwardPaginationData}
                                                            />
                                                        </div>
                                                    ))
                                                    }
                                                </InfiniteScroll>
                                            </Box>

                                        </Col>
                                    ) : (onwardResults.length < 1 && returnResults.length > 0) ? (
                                      <Col xs={6} sm={6} md={6} lg={6} className="flitercolumstick-left">
                                          <Box className="filter-msg-output">found 0 flight from {fsRoundReqData.from} to {fsRoundReqData.to}</Box>
                                          <Row className="viewroundresultbooking addonbackground_round">
                                            <Col xs={6} sm={6} md={6} lg={6}>
                                                <Box className="tltrndheader text-left">Select Onward Flights</Box>
                                            </Col>
                                          </Row>
                                          <p className='no-flight-roundtrip-p'>No Results Found</p>
                                      </Col>
                                    ) : null}
                                    {returnResults.length > 0 ? (
                                        <Col xs={6} sm={6} md={6} lg={6} className="flitercolumstick-left">
                                            <Box className="scrollingdiv" id="return">
                                                <Box className="filter-msg-output">found {Object.keys(returnResults).length} flights from {fsRoundReqData.to} to {fsRoundReqData.from}</Box>
                                                <Row className="viewroundresultbooking addonbackground_round">

                                                    <Col xs={6} sm={6} md={6} lg={6}>
                                                        <Box className="tltrndheader text-left">Select Return Flights</Box>
                                                    </Col>
                                                    <Col xs={6} sm={6} md={6} lg={6}>

                                                        <Box className={classes.rndsortbox}>
                                                            <Box className={classes.drpsort}>Sort By</Box>
                                                            <Box className={classes.drpsort}>
                                                                <FormControl variant="standard" className={classes.formControl}>
                                                                    <Select
                                                                        className={[classes.selectbx, "selectsortby"]}
                                                                        native
                                                                        value={returnToggleSort.value}
                                                                        onChange={(e) => toggleSortFnc(e, 'return')}
                                                                        inputProps={{
                                                                          name: 'age',
                                                                          id: 'age-native-simple'
                                                                        }}
                                                                    >
                                                                        {
                                                                            sortObj.map((item, index) => {
                                                                              return <option key={index} className={classes.itemopt} value={item.value}>{item.name}</option>
                                                                            })
                                                                        }

                                                                    </Select>
                                                                </FormControl>
                                                            </Box>
                                                        </Box>
                                                    </Col>
                                                </Row>

                                                <InfiniteScroll
                                                    dataLength={returnPage * 20}
                                                    next={handleReturnObserver}
                                                    scrollableTarget={'return'}
                                                    hasMore={returnLoadMore}
                                                    loader={<h4>Loading...</h4>}>

                                                    {returnpaginationData.map((value, index) => (
                                                        <div>
                                                            <RoundTripResults 
                                                              results={value} 
                                                              key={index} 
                                                              keyValue={'ret-' + index} 
                                                              returnSelected={returnFlightSelect} 
                                                              type="return" 
                                                              showNetFare={filterReturn.showNet} 
                                                              showWhatsapp={showWhatsapp}
                                                              onActiveBg={activeRetBg} 
                                                              onToggleSelectedFlight={onToggleSelectedFlight}
                                                              selectedFlight={selectedFlight}
                                                              paginationData={returnpaginationData}
                                                            />
                                                        </div>
                                                    ))
                                                    }
                                                </InfiniteScroll>
                                            </Box>
                                        </Col>
                                    ) : (returnResults.length < 1 && onwardResults.length > 0) ? (
                                      <Col xs={6} sm={6} md={6} lg={6} className="flitercolumstick-left">
                                          <Box className="filter-msg-output">found 0 flight from {fsRoundReqData.to} to {fsRoundReqData.from}</Box>
                                          <Row className="viewroundresultbooking addonbackground_round">
                                            <Col xs={6} sm={6} md={6} lg={6}>
                                                <Box className="tltrndheader text-left">Select Return Flights</Box>
                                            </Col>
                                          </Row>
                                          <p className='no-flight-roundtrip-p'>No Results Found</p>
                                      </Col>
                                    ) : null}

                                </>
                            )}

                            {(!!roundError) && (
                                <NotFoundFlight />
                            )}

                            
                        </Row>
                        {
                            fsRoundData && onwardResults.length < 1 && returnResults.length < 1 && fsRoundCount === BookingSourceLength &&
                            // <p>No flights found</p>
                            <NotFindData handleReset={handleReset} />
                        }
                    </Col>

                    <Col xs={12} sm={12} md={9} lg={9} className="white-bg-xs roundtripresult-mob">
                      {fsRoundCount !== BookingSourceLength && (
                            <div>
                                <FlightLoader />
                            </div>

                        )}
                        {isRoundLoading && (
                            <div>

                                {/* <FlightLoader /> */}
                                {[...Array(skeltonCount)].map((e, i) => <RoundTripSearchSkelton key={i} />)}
                            </div>
                        )}

                        <Row>

                            {fsRoundData && (
                                <>
                                  <SwipeableViews enableMouseEvents>
                                    <div>
                                      {onwardResults.length > 0 && (

                                        <Col xs={12}  className="flitercolumstick-left">
                                          <Box className="scrollingdiv" id="onward">
                                            <Box className="filter-msg-output">found {Object.keys(onwardResults).length} flights from {fsRoundReqData.from} to {fsRoundReqData.to}</Box>
                                              <Row>
                                                <Col xs={9}>
                                                  <Row className="viewroundresultbooking addonbackground_round">

                                                      <Col xs={12} >
                                                          <Box className="tltrndheader text-left">Select Onward Flights</Box>
                                                      </Col>
                                                      <Col xs={12}>

                                                          <Box className={classes.rndsortbox}>
                                                              <Box className={classes.drpsort}>Sort By</Box>
                                                              <Box className={classes.drpsort}>
                                                                  <FormControl variant="standard" className={classes.formControl}>
                                                                      <Select
                                                                          className={classes.selectbx}
                                                                          native
                                                                          value={onwardToggleSort.value}
                                                                          onChange={(e) => toggleSortFnc(e, 'onward')}
                                                                          inputProps={{
                                                                            name: 'age',
                                                                            id: 'age-native-simple'
                                                                          }}
                                                                      >
                                                                          {
                                                                              sortObj.map((item, index) => {
                                                                                return <option key={index} className={classes.itemopt} value={item.value}>{item.name}</option>
                                                                              })
                                                                          }
                                                                      </Select>
                                                                  </FormControl>
                                                              </Box>
                                                          </Box>
                                                      </Col>

                                                  </Row>
                                                </Col>
                                                <Col xs={3} className="pl-0">
                                                  <Row className="viewroundresultbooking addonbackground_round">
                                                      <Box className="tltrndheader text-left"> Return Flights</Box>
                                                  </Row>
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col xs={9}>      
                                                  <InfiniteScroll
                                                      className='scroll-roundtripresult'
                                                      dataLength={onwardPage * 20}
                                                      next={handleObserver}
                                                      scrollableTarget={'onward'}
                                                      hasMore={onwardLoadMore}
                                                      loader={<h4>Loading...</h4>}>

                                                      {onwardPaginationData.map((value, index) => (
                                                          <div>
                                                              <RoundTripResults 
                                                                results={value} 
                                                                key={index} 
                                                                keyValue={'on-' + index} 
                                                                onwardSelected={onwardFlightSelect} 
                                                                type="onward" 
                                                                showNetFare={filterOnward.showNet} 
                                                                showWhatsapp={showWhatsapp}
                                                                onActiveBg={activeOnBg} 
                                                                onToggleSelectedFlight={onToggleSelectedFlight}
                                                                selectedFlight={selectedFlight}
                                                                paginationData={onwardPaginationData}
                                                                />
                                                          </div>
                                                      ))
                                                      }
                                                  </InfiniteScroll>
                                                </Col>
                                            
                                                <Col xs={3} className="pl-0">                           
                                                  <InfiniteScroll
                                                      className='scroll-roundtripresult'
                                                      dataLength={returnPage * 20}
                                                      next={handleReturnObserver}
                                                      scrollableTarget={'return'}
                                                      hasMore={returnLoadMore}
                                                      loader={<h4>Loading...</h4>}>

                                                      {returnpaginationData.map((value, index) => (
                                                            <div>
                                                              <RoundTripResultsMini 
                                                                results={value} 
                                                                key={index} 
                                                                keyValue={'ret-' + index} 
                                                                returnSelected={returnFlightSelect} 
                                                                type="return" 
                                                                showNetFare={filterReturn.showNet}
                                                                showWhatsapp={showWhatsapp} 
                                                                onActiveBg={activeRetBg} 
                                                                onToggleSelectedFlight={onToggleSelectedFlight}
                                                                selectedFlight={selectedFlight}
                                                                paginationData={returnpaginationData}
                                                              />
                                                          </div>
                                                      ))
                                                      }
                                                  </InfiniteScroll>
                                                </Col>
                                              </Row>
                                            </Box>

                                          </Col>
                                        )}
                                    </div>
                                    <div>
                                    {returnResults.length > 0 && (
                                        <Col xs={12} sm={6} md={6} lg={6} className="flitercolumstick-left">
                                            <Box className="scrollingdiv" id="return">
                                                <Box className="filter-msg-output">found {Object.keys(returnResults).length} flights from {fsRoundReqData.to} to {fsRoundReqData.from}</Box>
                                                  <Row>
                                                    <Col xs={3} className="pr-0">
                                                      <Row className="viewroundresultbooking addonbackground_round">
                                                          <Box className="tltrndheader text-left"> Onword Flights</Box>
                                                      </Row>
                                                    </Col>
                                                    <Col xs={9}>
                                                      <Row className="viewroundresultbooking addonbackground_round">

                                                          <Col xs={12} sm={12} md={6} lg={6}>
                                                              <Box className="tltrndheader text-left">Select Return Flights</Box>
                                                          </Col>
                                                          <Col xs={12} sm={12} md={6} lg={6}>

                                                              <Box className={classes.rndsortbox}>
                                                                  <Box className={classes.drpsort}>Sort By</Box>
                                                                  <Box className={classes.drpsort}>
                                                                      <FormControl variant="standard" className={classes.formControl}>
                                                                          <Select
                                                                              className={classes.selectbx}
                                                                              native
                                                                              value={returnToggleSort.value}
                                                                              onChange={(e) => toggleSortFnc(e, 'return')}
                                                                              inputProps={{
                                                                                name: 'age',
                                                                                id: 'age-native-simple'
                                                                              }}
                                                                          >
                                                                              {
                                                                                  sortObj.map((item, index) => {
                                                                                    return <option key={index} className={classes.itemopt} value={item.value}>{item.name}</option>
                                                                                  })
                                                                              }

                                                                          </Select>
                                                                      </FormControl>
                                                                  </Box>
                                                              </Box>
                                                          </Col>
                                                      </Row>
                                                    </Col>
                                                   
                                                </Row>

                                                <Row>
                                                  <Col xs={3} className="pr-0">                           
                                                      <InfiniteScroll
                                                          className='scroll-roundtripresult'
                                                          dataLength={returnPage * 20}
                                                          next={handleReturnObserver}
                                                          scrollableTarget={'return'}
                                                          hasMore={returnLoadMore}
                                                          loader={<h4>Loading...</h4>}>

                                                        {onwardPaginationData.map((value, index) => (
                                                          <div>
                                                              <RoundTripResultsMini 
                                                                results={value} 
                                                                key={index} 
                                                                keyValue={'on-' + index} 
                                                                onwardSelected={onwardFlightSelect} 
                                                                type="onward" 
                                                                showNetFare={filterOnward.showNet} 
                                                                showWhatsapp={showWhatsapp}
                                                                onActiveBg={activeOnBg} 
                                                                onToggleSelectedFlight={onToggleSelectedFlight}
                                                                selectedFlight={selectedFlight}
                                                                paginationData={onwardPaginationData}
                                                                />
                                                          </div>
                                                        ))}

                                                      </InfiniteScroll>
                                                    </Col>
                                                    <Col xs={9}>                           
                                                      <InfiniteScroll
                                                          className='scroll-roundtripresult'
                                                          dataLength={returnPage * 20}
                                                          next={handleReturnObserver}
                                                          scrollableTarget={'return'}
                                                          hasMore={returnLoadMore}
                                                          loader={<h4>Loading...</h4>}>

                                                          {returnpaginationData.map((value, index) => (
                                                                <div>
                                                                  <RoundTripResults 
                                                                    results={value} 
                                                                    key={index} 
                                                                    keyValue={'ret-' + index} 
                                                                    returnSelected={returnFlightSelect} 
                                                                    type="return" 
                                                                    showNetFare={filterReturn.showNet} 
                                                                    showWhatsapp={showWhatsapp}
                                                                    onActiveBg={activeRetBg} 
                                                                    onToggleSelectedFlight={onToggleSelectedFlight}
                                                                    selectedFlight={selectedFlight}
                                                                    paginationData={returnpaginationData}
                                                                  />
                                                              </div>
                                                          ))
                                                          }
                                                      </InfiniteScroll>
                                                    </Col>
                                                
                                                  
                                                  </Row>
                                              </Box>
                                          </Col>
                                      )}
                                    </div>
                                  </SwipeableViews>

                                </>
                            )}

                            {(!!roundError) && (
                                <NotFoundFlight />
                            )}
                        </Row>
                        {
                            fsRoundData && onwardResults.length < 1 && returnResults.length < 1 && fsRoundCount === BookingSourceLength &&
                            // <p>No flights found</p>
                            <NotFindData handleReset={handleReset} />
                        }
                    </Col>

                </Row>
                {sharedFlightList.length > 0 &&(
                <Box className='share-container-round '> 
                  <Box className='reset-btn'>
                    <Button variant="text" onClick={()=> {setSharedFlightList([]); setSelectedFlight([])}}>Reset All</Button>
                  </Box>
                  <Row className='mb-0'>
                    {sharedFlightList.length > 0 && sharedFlightList.map((item, index) => 
                    <>
                      <Col xs={12}>
                        <Button onClick={()=>{onToggleSelectedFlight(item.provabAuthKey)}} className='share-cross-btn'><CloseIcon /></Button>
                      </Col>
                      <Row className='share-row-wrapper'>
                       
                      
                        <Col xs={3} className="share-img-wrapper">
                          {/* <Box className='share-img'><img src={airlineImg} /></Box> */}
                          <Box className={'itwgo-60x35_' + item.flightCode}></Box>
                        </Col>
                        <Col  xs={7}>
                          <p className='share-ttl-1'>{item.flightName + " (" + item.flightCode + "-" + item.flightNumber + ")"}</p>
                          <p className='share-ttl-1'>{item.from_city + " - " + item.to_city }</p>
                          <p className='share-ttl-1'>{(item.total_stops > 0 && item.segment_details.length > 0) ? "Via (" + item.segment_details.slice(0, -1).map(x=> x.DestinationDetails.CityName).join(", ") + ")" : null}</p>
                        </Col>
                        
                        <Col xs={12}>
                          
                        <p className='share-ttl-1'>{"Departure: " + item.from_city_time + ", " + flightDate(item.from_city_date) + " " + new Date(item.from_city_year).getFullYear() + ", " + new Date(item.from_city_year).toLocaleString('en-us', {weekday:'short'}) }</p>
                          <p className='share-ttl-1'>{"Arrival: " + item.to_city_time + ", " + flightDate(item.to_city_date) + " " + new Date(item.to_city_year).getFullYear() + ", " + new Date(item.to_city_year).toLocaleString('en-us', {weekday:'short'}) }</p>
                          <Box className='d-flex'>
                              <p className='share-ttl-1'>Faretype - {item.fare_type}  </p>
                            </Box>
                            <Box className='d-flex'>
                              <p className='share-ttl-1'>Duration - {item.total_duration} {item.total_stops > 0 ? `(${item.total_stops} ${item.total_stops > 1 ? "stops" : "stop"})` : null} </p>
                            </Box>
                          {sharedFlightList.length > 0 && (
                            <Box className='d-flex'>
                              <p className='share-ttl-1'>Price - Rs.  </p>
                              <TextField 
                                className='share-price-input' 
                                type="text" 
                                variant="standard" 
                                size="small" 
                                placeholder="Enter Price Here"
                                 value={sharedFlightList[index].price || ""}
                                onChange={(e)=>handleInputChange(e, index, "price")}
                              />
                            </Box>
                          )}

                            <TextField 
                              className='share-text-input mb-3' 
                              type="text" 
                              variant="standard" 
                              size="small" 
                              placeholder="Type Your Text Here"
                              value={sharedFlightList[index].remarks || ""}
                              onChange={(e)=>handleInputChange(e, index, "remarks")}
                              ></TextField>
                        </Col>
                      </Row>
                    </>
                    )}
                    <Col xs={12}>
                     <TextField 
                          className='share-outline-input mt-1' 
                          type="text" 
                          variant="outlined" 
                          size="small" 
                          placeholder="Type mobile number"
                          onChange={(e)=>handleSharedMobileNum(e)}
                          name="sharedMobileNum"
                          value={sharedMobileNum}
                          error={sharedMobileNumError}
                          helperText={sharedMobileNumError}
                          />
                    </Col>
                    <Col xs={6} md={3} className="pr-0">
                      <Button onClick={() => onShareByWhatsApp()} className='share-btn btn-green'>Share</Button>
                    </Col>
                    <Col xs={5} md={3} className="pr-0 pr-xs-3">
                      <CopyToClipboard text={`${sharedFlightList.map(item => {
                        return (
                          `${item.flightName} (${item.flightCode + "-" + item.flightNumber}): ${item.type}\n${item.from_city + " - " + item.to_city}${(item.total_stops > 0 && item.segment_details.length > 0) ? " Via (" + item.segment_details.slice(0, -1).map(x => x.DestinationDetails.CityName).join(", ") + ")" : ""}\nDeparture: ${item.from_city_time + ", " + flightDate(item.from_city_date) + " " + new Date(item.from_city_year).getFullYear() + ", " + new Date(item.from_city_year).toLocaleString('en-us', {weekday:'short'}) + "\nArrival: " + item.to_city_time + ", " + flightDate(item.to_city_date) + " " + new Date(item.to_city_year).getFullYear() + ", " + new Date(item.to_city_year).toLocaleString('en-us', {weekday:'short'})}\nDuration: ${item.total_duration}${item.total_stops > 0 ? `(${item.total_stops} ${item.total_stops > 1 ? "stops" : "stop"})` : ""}\nFare type: ${item.fare_type}\n${item.price !== 0 ? `Price: ${item.price} (For ${fsRoundReqData.child + fsRoundReqData.adult + fsRoundReqData.infant} Traveller(s))` : ""}\n${item.remarks !== null ? `Remarks: ${item.remarks}` : ''}\n\n`
                        )
                      }).join('')}Note :  All airfares are subject to availability at time of booking and are not guaranteed until purchased and the e-tickets have been issued.`}
                        onCopy={() => console.log("Coppied")}>
                        <Button className='share-btn'>Copy</Button>
                      </CopyToClipboard>
                    </Col>
                    <Col xs={12} md={6}>
                    <Button type="button" className='share-btn mt-3' onClick={() => copyTableFormat()} >Copy for Email </Button>
                   
                      <div id="tableDataRound" className='table-copy'>
                        <table>
                          <thead>
                            <tr>
                              <th>Type</th>
                              <th>Sector</th>
                              <th>Stop(s)</th>
                              <th>Flight No</th>
                              <th>Airline</th>
                              <th>Dept Time</th>
                              <th>Arrival Time</th>
                              <th>Duration</th>
                              <th>Fare</th>
                              <th>Fare Type</th>
                            </tr>
                          </thead>
                          <tbody>
                            {sharedFlightList.map(item => {
                            return (
                             
                              <tr>
                                <td>{item.type}</td>
                                <td>{item.from_city + " - " + item.to_city} </td>
                                <td>{item.total_stops > 0 ? `${item.total_stops} ${item.total_stops > 1 ? "stops" : "stop"}` : "Non-Stop"} {(item.total_stops > 0 && item.segment_details.length > 0) ? "(Via " + item.segment_details.slice(0, -1).map(x => x.DestinationDetails.CityName).join(", ") + ")" : ""}</td>
                                <td>{item.flightCode + "-" + item.flightNumber}  </td>
                                <td>{item.flightName}</td>
                                <td>{item.from_city_time + ", " + flightDate(item.from_city_date) + ", " + new Date(item.from_city_year).getFullYear()}</td>
                                <td>{item.to_city_time + ", " + flightDate(item.to_city_date) + ", " + new Date(item.to_city_year).getFullYear()}</td>
                                <td>{item.total_duration}</td>
                                <td>{item.price + ` (For ${fsRoundReqData.child + fsRoundReqData.adult + fsRoundReqData.infant} Traveller(s))`}</td>
                                <td>{item.fare_type}</td>
                              </tr>
                             
                              )
                              })}
                          </tbody>
                        </table>
                        <p><b>Note: </b>All airfares are subject to availability at time of booking and are not guaranteed 
                        until purchased and the e-tickets have been issued. </p>
                       </div>
                  
                  </Col>
                  </Row>
                </Box>
                )}
            </Container>
            <div>
              <div className='filter-btn-float'>
                <Button onClick={toggleFilter}><FilterAltIcon />Apply Filter</Button>
              </div>
            </div>
            
            {!!fsRoundData && (
                <>
                    {!!onFlight && (
                        <RoundTripBook onward={onFlight} returns={retFlight} />
                    )}
                </>
            )}

        </>
  )
}

export default RoundTripSearchResults

const sortObj = [
  { name: 'Price - Low', value: 'priceLow' },
  { name: 'Price - High', value: 'priceHigh' },
  { name: 'Departure - Early', value: 'departureLow' },
  { name: 'Departure - Late', value: 'departureHigh' },
  { name: 'Arrival - Early', value: 'arrivalLow' },
  { name: 'Arrival - Late', value: 'arrivalHigh' },
  { name: 'Duration - Short', value: 'durationLow' },
  { name: 'Duration - Long', value: 'durationHigh' },
  { name: 'Airline - Asc', value: 'airlineLow' },
  { name: 'Airline - Desc', value: 'airlineHigh' }
]