import React, { useLayoutEffect, lazy, Suspense } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import ActivityDetails from './pages/activities/activity-details/ActivityDetails';
import LoginWithToken from './pages/LoginWithToken';
import Spinner from './pages/Spinner';
// import Home from './pages/home/Home';
// import Login from './pages/user/Login';
// import Registration from './pages/user/Registration'
// import AboutUs from './pages/aboutus/AboutUs'
// import OurServices from './pages/aboutus/OurServices'
// import PrivacyPolicy from './pages/aboutus/PrivacyPolicy'
// import Test from './Test'

const Home = lazy(() =>
  import('./pages/home/Home')
)
const Login = lazy(() =>
  import('./pages/user/Login')
)
const Registration = lazy(() =>
  import('./pages/user/Registration')
)
const AboutUs = lazy(() =>
  import('./pages/aboutus/AboutUs')
)
const OurServices = lazy(() =>
  import('./pages/aboutus/OurServices')
)
const PrivacyPolicy = lazy(() =>
  import('./pages/aboutus/PrivacyPolicy')
)
const TermsAndCondition = lazy(() =>
  import('./pages/aboutus/TermsAndCondition')
)
const RefundPolicy = lazy(() =>
  import('./pages/aboutus/RefundPolicy')
)

const PageNotFound = lazy(() =>
  import('./pages/PageNotFound')
)


const Main = () => {
  const location = useLocation()
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])
  return (
        <div>
            <Suspense fallback={<Spinner />}>
            <Switch>
                {/* <Route exact path="/test" component={Test}></Route> */}
                <Route exact path="/login" component={Login}></Route>
                <Route exact path="/sign-up" component={Registration}></Route>
                <Route exact path="/about-us" component={AboutUs}></Route>
                <Route exact path="/our-services" component={OurServices}></Route>
                <Route exact path="/privacy-policy" component={PrivacyPolicy}></Route>
                <Route exact path="/terms-and-condition" component={TermsAndCondition}></Route>
                <Route exact path="/refund-policy" component={RefundPolicy}></Route>
                <Route exact path="/loginWithToken" component={LoginWithToken}></Route>
                <Route exact path="/leh-ladakh-package-details" component={ActivityDetails}></Route>
                <Route path="/" component={Home} />

                {/* <Route path="*" component={PageNotFound} /> */}
            </Switch>
            </Suspense>
        </div>
  )
}

export default Main
