import React from 'react'
import { Box } from '@mui/material'
import { Col, Row } from 'react-bootstrap';
import imgnoflight from '../../../assets/images/no-flight.png'
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  notfoundddata: {
    textAlign: 'right',
    alignItems: 'right',
    justifyContent: 'center',
    padding: '50px 0px'
  },
  imgdata: {
    width: 100
  },
  para: {
    fontSize: 22,
    fontWeight: '700'
  },
  filtererrwrp: {
    backgroundColor: '#fff'
  },
  errfiltertxt: {
    padding: "20px 0px"
  }
})
function NotFoundFlight () {
  const classes = useStyles()
  return (
    <div className={classes.filtererrwrp} >
      <Row>
        <Col xs={4} sm={12} md={4} lg={4} xl={4}>
          <div className={classes.notfoundddata}>
            <div className={classes.imgfnd}><img src={imgnoflight} className={classes.imgdata}/></div>
          </div>
        </Col>
        <Col xs={8} sm={12} md={8} lg={8} xl={8} className="m-auto">
          <div className={classes.errfiltertxt}>
            <div className={classes.para}> Sorry, No flights found for this route.</div>
          </div>
        </Col>
      </Row>
        
    </div>
        

  )
}

export default NotFoundFlight

