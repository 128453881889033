import React from 'react'
import {Container, Grid } from '@mui/material';
import '../user/index.css'
import {logo} from "../user/index";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation,
    NavLink
  } from "react-router-dom";

 const Accountheader=(props)=> {

    return (
        <Container className="account-header">
            <Grid container>
                <Grid item md={4} xs={4}>
                <div className="logo-account">
                <NavLink className="linkdiv-set"  to="login">
                    <img src={logo} className="header-logo" alt="logo" />
                    </NavLink>
                </div>
                </Grid>
                <Grid item md={8} xs={8}>
                    <div className="link-user">
                        <h2 className="tlt-link">
                            <span>{props.subTitle}</span>
                            <Link className="ancor-link" to={props.link}>
                                <span className="linkcreateaccount">{props.title}</span>
                            </Link>
                        </h2>
                    </div>
                </Grid>
            </Grid>
        </Container>
    )
}
export default Accountheader;
