export function formatDate (dateString) {
  const date = new Date(dateString)

  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]

  const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

  const day = date.getDay()
  const dates = date.getDate()
  const monthIndex = date.getMonth()
  const year = date.getFullYear()

  return (
    dayNames[day] + ', ' + dates + ' ' + monthNames[monthIndex] + ' ' + year
  )
}

export function formatDate1 (dateString) {
  const date = new Date(dateString)
  const arr = dateString.split(' ')
  const times = arr[1].split(':')

  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]

  const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

  const day = date.getDay()
  const dates = date.getDate()
  const monthIndex = date.getMonth()
  const year = date.getFullYear()

  return (
    monthNames[monthIndex] + ' ' + dates + ', ' + dayNames[day] + ', ' + times[0] + ':' + times[1]
  )
}

export const getMonthsCalendarFare = () => {
  const monthsDropdown = []
  const monthName = new Array(
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  )

  const d = new Date()
  d.setDate(1)
  for (let i = 0; i <= 11; i++) {
    monthsDropdown.push(monthName[d.getMonth()] + ', ' + d.getFullYear())
    d.setMonth(d.getMonth() + 1)
  }
  return monthsDropdown
}

export const stringToDate = (dd) => {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]

  const d = dd ? dd.toString() : null
  const array = d? d.split(' ', 4) : null
  const m = array ?  monthNames.indexOf(array[1]) + 1 : null
  let r

  if (m > 9) {
    r = m
  } else {
    r = '0' + m
  }

  return  array ? array[2] + '-' + r + '-' + array[3] : null
}

export const flightDate = (dd) => {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]
  // : "30-03-2021"
  const d = dd.toString()
  const array = d.split('-')
  let mmNN = 0
  if (array[1] < 10) {
    const str = array[1].toString()
    mmNN = str.charAt(str.length - 1)
  } else {
    mmNN = array[1]
  }

  const m = monthNames[mmNN - 1]

  return array[0] + ' ' + m
}

export function reverseDate (str) {
  const d = str.toString()
  const array = str.split('-')
  return array[2] + '-' + array[1] + '-' + array[0]
}
export const flightDateFormat = (dd) => {
  const date = new Date(reverseDate(dd))

  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]

  const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

  // : "30-03-2021"
  const d = dd.toString()
  const array = d.split('-')
  let mmNN = 0
  if (array[1] < 10) {
    const str = array[1].toString()
    mmNN = str.charAt(str.length - 1)
  } else {
    mmNN = array[1]
  }

  const m = monthNames[mmNN - 1]

  // return m+' '+array[0]

  const da = date.toDateString().split(' ')

  // return date.toDateString()

  return da[0] + ' ' + da[2] + ' ' + da[1] + ' ' + da[3]
}

export const flightDateFormatMob = (dd) => {
  const date = new Date(reverseDate(dd))

  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]

  const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

  // : "30-03-2021"
  const d = dd.toString()
  const array = d.split('-')
  let mmNN = 0
  if (array[1] < 10) {
    const str = array[1].toString()
    mmNN = str.charAt(str.length - 1)
  } else {
    mmNN = array[1]
  }

  const m = monthNames[mmNN - 1]

  // return m+' '+array[0]

  const da = date.toDateString().split(' ')

  // return date.toDateString()

  return da[2] + ' ' + da[1]
}

export function formatDate2 (dateString) {
  if (dateString) {
    const date = new Date(dateString)
    const arr = dateString.toString().split(' ')
    const times = arr[1].split(':')

    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]

    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

    const day = date.getDay()
    const dates = date.getDate()
    const monthIndex = date.getMonth()
    const year = date.getFullYear()

    return (
      dayNames[day] + ', ' + dates + ' ' + monthNames[monthIndex] + ' ' + year + ', ' + times[0] + ':' + times[1]
    )
  }
}

export const getFormatData = (pushDate) => {
  const d = pushDate.toString()
  // 2016-01-04 10:34:23
  const array = d.split(' ')

  const s = array[0].split('-')

  return s[2] + '-' + s[1] + '-' + s[0]
}

const a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen ']
const b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety']

export const inWords = (num) => {
  if ((num = num.toString()).length > 9) { return 'overflow' } else {  }
  const n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/)
  if (!n) { return } else {  }
  let str = ''
  str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : ''
  str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : ''
  str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : ''
  str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : ''
  str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only ' : ''
  return str
}

export const getFormatData2 = (pushDate) => {
  if (pushDate != null) {
    const d = pushDate.toString()
    // 2016-01-04 10:34:23
    const array = d.split(' ')

    const s = array[0].split('-')

    return array[2] + '-' + array[1] + '-' + array[3]
  }
}

export const GMTConvertDate = (str) => {
  // "Thu Jun 09 2011 00:00:00 GMT+0530 (India Standard Time)"
  const mnths = {
    Jan: '01',
    Feb: '02',
    Mar: '03',
    Apr: '04',
    May: '05',
    Jun: '06',
    Jul: '07',
    Aug: '08',
    Sep: '09',
    Oct: '10',
    Nov: '11',
    Dec: '12'
  }
  const date = str.split(' ')

  return [date[2], mnths[date[1]], date[3]].join('-')
}

export function traveldate (dateString) {
  if (dateString) {
    const date = new Date(dateString)
    const arr = dateString.toString().split(' ')
    const times = arr[1].split(':')

    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]

    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

    const day = date.getDay()
    const dates = date.getDate()
    const monthIndex = date.getMonth()
    const year = date.getFullYear()

    return (
      dates + ' ' + monthNames[monthIndex] + ' ' + year
    )
  }
}

export function t_traveldate (dateString) {
  if (dateString) {
    const date = new Date(dateString)
    const arr = dateString.toString().split('T')
    const times = arr[1].split(':')

    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]

    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

    const day = date.getDay()
    const dates = date.getDate()
    const monthIndex = date.getMonth()
    const year = date.getFullYear()

    return (
      dates + ' ' + monthNames[monthIndex] + ' ' + year
    )
  }
}

export function t_traveldate_1 (dateString) {
  if (dateString) {
    const date = new Date(dateString)
    const arr = dateString.toString().split(' ')
    const times = arr[1].split(':')

    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]

    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

    const day = date.getDay()
    const dates = date.getDate()
    const monthIndex = date.getMonth()
    const year = date.getFullYear()

    return (
      dates + ' ' + monthNames[monthIndex] + ' ' + year
    )
  }
}

export function dayOlyDate (dateString) {
  if (dateString) {
    const date = new Date(dateString)

    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]

    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

    const day = date.getDay()
    const dates = date.getDate()
    const monthIndex = date.getMonth()
    const year = date.getFullYear()

    return (
      dayNames[day] + ', ' + dates + ' ' + monthNames[monthIndex] + ' ' + year
    )
  }
}

export function dayOlyDateMob (dateString) {
  if (dateString) {
    const date = new Date(dateString)

    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]

    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

    const day = date.getDay()
    const dates = date.getDate()
    const monthIndex = date.getMonth()
    const year = date.getFullYear()

    return (
     dates + ' ' + monthNames[monthIndex] 
    )
  }
}

export function dayDate (dateString) {
  if (dateString) {
    const date = new Date(dateString)
    const arr = dateString.toString().split(' ')
    const times = arr[1].split(':')

    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]

    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

    const day = date.getDay()
    const dates = date.getDate()
    const monthIndex = date.getMonth()
    const year = date.getFullYear()

    return (
      dayNames[day] + ', ' + dates + ' ' + monthNames[monthIndex] + ' ' + year + ', ' + times[0] + ':' + times[1]
    )
  }
}

export const getCalFareFormat = (date) => {
  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const ff = date.split(',')[0]
  const d = new Date()
  if (month.indexOf(ff) === d.getMonth()) {
    return stringToDate(d)
  } else {
    const g = month.indexOf(ff) + 1
    const t = (date.split(',')[1]).trim()

    return '01-' + g + '-' + t
  }
}

export const toDigit = (num) => {
  const stringToInt = parseFloat(num)
  const intToFixed = stringToInt.toFixed(2)
  const fixedToInt = parseFloat(intToFixed)
  return fixedToInt
}

export const bookingStatusLabel = (status) => {
  let actual_status = ''
  switch (status) {
    case 'BOOKING_CONFIRMED':
      return actual_status = 'CONFIRMED'
    case 'BOOKING_HOLD':
      return actual_status = 'HOLD'
    case 'BOOKING_CANCELLED':
      return actual_status = 'CANCELLED'
    case 'BOOKING_ERROR':
      return actual_status = 'ERROR'
    case 'BOOKING_INCOMPLETE':
      return actual_status = 'INCOMPLETE'
    case 'BOOKING_VOUCHERED':
      return actual_status = 'VOUCHERED'
    case 'BOOKING_PENDING':
      return actual_status = 'PENDING'
    case 'BOOKING_FAILED':
      return actual_status = 'FAILED'
    case 'BOOKING_INPROGRESS':
      return actual_status = 'INPROGRESS'
    case 'CANCELLATION_INPROGRESS':
      return actual_status = 'CNX INPROGRESS'
  }

  // return actual_status
}

export const addZeroes = (num) => {
  const dec = num.toString().split('.')[1]
  const len = dec && dec.length > 2 ? dec.length : 2
  return Number(num).toFixed(len)
}

export const IST_DDMMYYYY_T = () => {
  const today = new Date()
  const date = today.getDate() + '-' + parseInt(today.getMonth() + 1) + '-' + today.getFullYear() + '-' + today.getHours() + '-' + today.getMinutes()
  return date
}

export const today = () => {
  return new Date()
}
