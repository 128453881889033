import { Skeleton } from '@mui/material';
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './skeleton.css'
const TopDealsSkltn = () => {
  return (
      <div>
      <Container>
          <Row className="mt-3">
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="sktdiv">
                      <Skeleton animation="wave" variant="rectangular" width={300} height={30} />
                  </div>
              </Col>
              <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                  <div className="carddiv">
                      <div className="sktdiv"><Skeleton animation="wave" variant="rectangular" height={100} /></div>
                      <div className="sktdiv"><Skeleton animation="wave" variant="rectangular" height={50} /></div>
                      <div className="sktdiv"><Skeleton animation="wave" variant="rectangular" height={20} /></div>
                      <div className="sktdiv"><Skeleton animation="wave" variant="rectangular" width={100} height={25} /></div>
                  </div>
              </Col>
              <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                  <div className="carddiv">
                      <div className="sktdiv"><Skeleton animation="wave" variant="rectangular" height={100} /></div>
                      <div className="sktdiv"><Skeleton animation="wave" variant="rectangular" height={50} /></div>
                      <div className="sktdiv"><Skeleton animation="wave" variant="rectangular" height={20} /></div>
                      <div className="sktdiv"><Skeleton animation="wave" variant="rectangular" width={100} height={25} /></div>
                  </div>
              </Col>
              <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                  <div className="carddiv">
                      <div className="sktdiv"><Skeleton animation="wave" variant="rectangular" height={100} /></div>
                      <div className="sktdiv"><Skeleton animation="wave" variant="rectangular" height={50} /></div>
                      <div className="sktdiv"><Skeleton animation="wave" variant="rectangular" height={20} /></div>
                      <div className="sktdiv"><Skeleton animation="wave" variant="rectangular" width={100} height={25} /></div>
                  </div>
              </Col>
              <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                  <div className="carddiv">
                      <div className="sktdiv"><Skeleton animation="wave" variant="rectangular" height={100} /></div>
                      <div className="sktdiv"><Skeleton animation="wave" variant="rectangular" height={50} /></div>
                      <div className="sktdiv"><Skeleton animation="wave" variant="rectangular" height={20} /></div>
                      <div className="sktdiv"><Skeleton animation="wave" variant="rectangular" width={100} height={25} /></div>
                  </div>
              </Col>
          </Row>
          {/* <Row className="mt-3">
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="sktdiv">
                      <Skeleton animation="wave" variant="rect" width={300} height={30} />
                  </div>
              </Col>
              <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                  <div className="carddiv">
                      <div className="sktdiv"><Skeleton animation="wave" variant="rect" height={100} /></div>
                      <div className="sktdiv"><Skeleton animation="wave" variant="rect" height={50} /></div>
                      <div className="sktdiv"><Skeleton animation="wave" variant="rect" height={20} /></div>
                      <div className="sktdiv"><Skeleton animation="wave" variant="rect" width={100} height={25} /></div>
                  </div>
              </Col>
              <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                  <div className="carddiv">
                      <div className="sktdiv"><Skeleton animation="wave" variant="rect" height={100} /></div>
                      <div className="sktdiv"><Skeleton animation="wave" variant="rect" height={50} /></div>
                      <div className="sktdiv"><Skeleton animation="wave" variant="rect" height={20} /></div>
                      <div className="sktdiv"><Skeleton animation="wave" variant="rect" width={100} height={25} /></div>
                  </div>
              </Col>
              <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                  <div className="carddiv">
                      <div className="sktdiv"><Skeleton animation="wave" variant="rect" height={100} /></div>
                      <div className="sktdiv"><Skeleton animation="wave" variant="rect" height={50} /></div>
                      <div className="sktdiv"><Skeleton animation="wave" variant="rect" height={20} /></div>
                      <div className="sktdiv"><Skeleton animation="wave" variant="rect" width={100} height={25} /></div>
                  </div>
              </Col>
              <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                  <div className="carddiv">
                      <div className="sktdiv"><Skeleton animation="wave" variant="rect" height={100} /></div>
                      <div className="sktdiv"><Skeleton animation="wave" variant="rect" height={50} /></div>
                      <div className="sktdiv"><Skeleton animation="wave" variant="rect" height={20} /></div>
                      <div className="sktdiv"><Skeleton animation="wave" variant="rect" width={100} height={25} /></div>
                  </div>
              </Col>
          </Row> */}
      </Container>
      </div>
  );
}

export default TopDealsSkltn
