import React from 'react'
import { Col, Container, Row , } from 'react-bootstrap'
import '../../flights/flight-booking/flight-summary/flightsummary.css'
import '../activity-details/selectactivity.css'
import '../activity-results/activityresult.css'

const ActivityBooked = () => {
    
    return (
        <div>
            <Container className='selectactivity-container'>
                <Row>
                    <Col xs={12} sm={3}>
                    </Col>
                    <Col xs={12} sm={6} className="success-dialog">
                        <div class="card">
                            <div className='card-body'>
                                <div class="success alert">
                                <div class="alert-body">
                                    {/* Success ! */}
                                </div>
                                </div>
                            </div>
                            <h1>Success</h1> 
                            <p>We have your received your enquiry.<br/> we'll be in touch shortly!</p>
                        </div>
                    </Col>
                    <Col xs={12} sm={3}>
                    </Col>
                </Row>
                
            </Container>
    </div>
    )
}

export default ActivityBooked
