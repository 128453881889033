import { getAuth, login } from '../../../utility/apiConfig'
import { ACCESS_TOKEN, API_BASE_URL, REFRESH_TOKEN } from '../../../utility/apiConstants'
export const LOADER = 'LOADER'
export const LOGIN = 'LOGIN'
export const ERROR = 'ERROR'

export const loader = () => {
  return {
    type: LOADER
  }
}

export const loginReqs = (data) => {
  return {
    type: LOGIN,
    payload: data
  }
}

export const errorReqs = (error) => {
  return {
    type: ERROR,
    payload: error
  }
}

export const loginRequest = (data, history) => {
  return (dispatch) => {
    try {
      dispatch(loader())

      login(API_BASE_URL + 'login/login_auth', data)
        .then((response) => {

          dispatch(loginReqs(response.data))

          if (response.data.status === true) {
            if (response.data.type === 'login') {
              localStorage.setItem(ACCESS_TOKEN, response.data.token.access_token)
              localStorage.setItem(REFRESH_TOKEN, response.data.token.refresh_token)
            }

            try {
              getAuth(API_BASE_URL + 'user/account', null)
                .then((response) => {
                  if (response.data.status) {
                    localStorage.setItem('login_data', JSON.stringify(response.data))
                    history.push('/flight')
                  }
                })
            } catch (error) {
              // dispatch(errorReqs(''))
            }
          } else {
            dispatch(errorReqs(response.data.message))
          }
        })
        .catch((error) => {
          if (error === 'Error: Network Error') {
            dispatch(errorReqs('Network error please try again'))
          } else {
            dispatch(errorReqs(error.response.data.message))
          }
        })
    } catch (error) {
      dispatch(errorReqs('Somthing went wrong please try again'))
    }
  }
}
