import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Col, Container, Nav, Navbar, NavDropdown, Row } from 'react-bootstrap'
import './header.css'
// import './updatedheader.css'
import {
  Menu,
  MenuItem,
  MenuButton,
  SubMenu,
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import logo from "../../assets/images/itwlogo.png";
import profileImg from "../../assets/images/images/myprofile.png";
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { Badge, Box, Button, Grow, MenuList, Paper, Popper } from '@mui/material';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import makeStyles from '@mui/styles/makeStyles';
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getUserAccountDetails } from '../../store/user/actions/userAcountActions';
import { getAuth } from '../../utility/apiConfig';
import { API_BASE_URL, PROFILE_IMAGE } from '../../utility/apiConstants';
import TopUpImg from '../../assets/images/wallet.png'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

const Header = () => {
  const dispatch = useDispatch();
  const { isLoading, userDetails, error } = useSelector(state => state.user)

  const classes = useStyles();
  const [hovered, setHovered] = useState(false);
  const toggleHover = () => setHovered(!hovered);
  const [balanceHovered, setbalanceHovered] = useState(false);
  const togglebalanceHoveredr = () => setbalanceHovered(!balanceHovered);
  const [isOpen, setisOpen] = useState(false);
  const dropdownMenu = () => setisOpen(!isOpen);
  const [account, setAccountMenu] = useState(false);
  const [openNav, setOpenNav] = React.useState(false);

  const handleClickNav = () => {
    setOpenNav((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpenNav(false);
  };
  const accountShowDropdown = (e) => {
    setAccountMenu(!account);
  }
  const accountHideDropdown = e => {
    setAccountMenu(false);
  }

  const [report, setReportMenu] = useState(false);
  const reportShowDropdown = (e) => {
    setReportMenu(!report);
  }
  const reportHideDropdown = e => {
    setReportMenu(false);
  }


  const [admin, setAdminMenu] = useState(false);
  const adminShowDropdown = (e) => {
    setAdminMenu(!admin);
  }
  const adminHideDropdown = e => {
    setAdminMenu(false);
  }

  const [queue, setQueueMenu] = useState(false);
  const queueShowDropdown = (e) => {
    setQueueMenu(!queue);
  }
  const queueHideDropdown = e => {
    setQueueMenu(false);
  }

  const [balance, setBalanceMenu] = useState(false);
  const balanceShowDropdown = (e) => {
    setBalanceMenu(!balance);
  }
  const balanceHideDropdown = e => {
    setBalanceMenu(false);
  }

  const [notifications, setNotificationsMenu] = useState(false);
  const [notificationsList, setNotificationsList] = useState([]);

  const notificationShowDropdown = (e) => {
    setNotificationsMenu(!notifications);
    onButtonClick()
  }


  const notificationHideDropdown = e => {
    setNotificationsMenu(false);
  }

  const onButtonClick = useCallback(() => {
    getAuth(API_BASE_URL + 'utilities/events_notification')
      .then((response) => {
        if (response.data.status) {
          if (response.data.data.notification_list) {
            setNotificationsList(response.data.data.notification_list)
            // console.log('notificationsList', notificationsList);
          }

        }
      })
      .catch((err) => { console.log(err); })
  }, []);


  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('login_data');
    //   history.push('/login')

  };

  const handleClosePop = () => {
    setOpen(false);
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);


  useEffect(() => {
    dispatch(getUserAccountDetails())
  }, [dispatch]);

  //updated menu items

  return (
    <div className="header" id="header">
      {/* {userDetails ? ( */}

      <Container>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="headercol">
            <Navbar collapseOnSelect expand="lg" variant="light" className="NavbarHeader">
              <Navbar.Brand href="/" className='logoContainer'>
                <NavLink to="/flight">
                  <img src={userDetails && userDetails.logo ? PROFILE_IMAGE + userDetails?.logo : logo} className="itw-logo" alt="logo" />
                </NavLink>
              </Navbar.Brand>
              
               
                <ClickAwayListener
                    mouseEvent="onMouseDown"
                    touchEvent="onTouchStart"
                    onClickAway={handleClickAway}
                  >
                    <Box className='box-header-clickaway'>
                      <Button aria-controls="responsive-navbar-nav" type="button" 
                      aria-label="Toggle navigation" class="navbar-toggler" onClick={handleClickNav}><span class="navbar-toggler-icon"></span></Button>
            
                        <>
                        <Navbar className={`navbar-collapse collapse ${openNav ? 'show' : null}`}>
                         <Nav className="navbar-menu">
                          {/* <MenuButton className="upadtedmenuitems" ><Nav.Link href="/leh-ladakh-package-details"><Badge className='badge-custom-flight-trending' badgeContent={'Trending'} color="primary"> */}
                          {/* Leh Package </Badge></Nav.Link></MenuButton> */}
                            <Menu menuButton={<MenuButton className="upadtedmenuitems">
                              {
                                userDetails && userDetails.special_fare ?
                                  <Badge className='badge-custom-flight' badgeContent={'New'} color="primary">
                                    Flight
                                  </Badge>
                                  :
                                  <span>Flight</span>}
                            </MenuButton>}>
                              <MenuItem><Nav.Link href="/flight">Search</Nav.Link></MenuItem>
                              {
                                userDetails && userDetails.special_fare ?
                                  <MenuItem><Nav.Link href="/flight/specialfare">Special fare <span class="badge badge-secondary spcl-badge">New</span></Nav.Link></MenuItem>
                                  : null
                              }
                              <MenuItem><Nav.Link href="/flight/airPassengerCalendar">Air Passenger Calendar</Nav.Link></MenuItem>
                            </Menu>

                            {/* <Menu menuButton={<MenuButton className="upadtedmenuitems">Hotel</MenuButton>}><MenuItem><Nav.Link href="/hotel">Search</Nav.Link></MenuItem></Menu> */}

                            <Menu
                              menuButton={<MenuButton className="upadtedmenuitems">Accounts</MenuButton>}>

                              <MenuItem><Nav.Link href="/accountledger">Account Ledger</Nav.Link></MenuItem>
                              <MenuItem><Nav.Link href="/transactionlog">Transaction Logs</Nav.Link></MenuItem>
                              <MenuItem><Nav.Link href="/manage-gst">Manage GST</Nav.Link></MenuItem>
                              <MenuItem><Nav.Link href="/updatebalance">Upload Balance</Nav.Link></MenuItem>
                              <MenuItem><Nav.Link href="/top-up-recharge">Top up balance</Nav.Link></MenuItem>

                            </Menu>
                            <Menu menuButton={<MenuButton className="upadtedmenuitems">Bookings</MenuButton>}>

                              <MenuItem><Nav.Link href="/flight-report">Flight</Nav.Link></MenuItem>
                              {/* <MenuItem><Nav.Link href="/hotel-report">Hotel</Nav.Link></MenuItem> */}
                              {/* <MenuItem><Nav.Link href="/bus-report">Bus</Nav.Link></MenuItem> */}

                            </Menu>

                            <Menu menuButton={<MenuButton className="upadtedmenuitems">Admin</MenuButton>}>

                              <SubMenu label="My Markup">
                                <MenuItem><Nav.Link href="/markup">Flight</Nav.Link></MenuItem>
                                {/* <MenuItem><Nav.Link href="/hotel-markup">Hotel</Nav.Link></MenuItem> */}
                                {/* <MenuItem><Nav.Link href="/markup">Bus</Nav.Link></MenuItem> */}
                              </SubMenu>
                              {/* <MenuItem><Nav.Link href="/commission">Commission</Nav.Link ></MenuItem> */}
                              <MenuItem><Nav.Link href="/bankdetails">Bank Details</Nav.Link ></MenuItem>
                              <MenuItem><Nav.Link href="/setbalance">Set Balance Alert</Nav.Link ></MenuItem>
                              <MenuItem><Nav.Link href="/upload-logo">Upload Logo </Nav.Link ></MenuItem>
                            </Menu>

                            <Menu menuButton={<MenuButton className="upadtedmenuitems">Queue</MenuButton>}>

                              <SubMenu label="Flight Queue">
                                <MenuItem><Nav.Link href="/reschedule-queue">Rescheduling Queue</Nav.Link></MenuItem>
                                <MenuItem><Nav.Link href="/cancellation-queue">Cancellation Queue</Nav.Link></MenuItem>
                              </SubMenu>
                              <SubMenu label="Hotel Queue">
                                <MenuItem><Nav.Link href="/reschedule-queue">Rescheduling Queue</Nav.Link></MenuItem>
                                <MenuItem><Nav.Link href="/hotel-cancellation-queue">Cancellation Queue</Nav.Link></MenuItem>
                              </SubMenu>
                              {/* <SubMenu label="Bus Queue">
                                                      <MenuItem><Nav.Link href="/reschedule-queue">Rescheduling Queue</Nav.Link></MenuItem>
                                                      <MenuItem><Nav.Link href="/cancellation-queue">Cancellation Queue</Nav.Link></MenuItem>
                                                    </SubMenu> */}
                            </Menu>
                          </Nav>
                          <Nav className="topheader-dropdown">
                            <Box className="top-header-dropdown">
                              <NavLink to="/top-up-recharge" >  
                                <Box className='header-topup'>
                                  <img id='blink' src={TopUpImg} alt='top-up'/> <span>Top-Up</span>
                                </Box>
                              </NavLink>
                            
                              <div className="dropdown-profile" >
                              
                                <Button
                                  className="profile"
                                  variant="contained"
                                  color="primary"
                                  endIcon={<ArrowDropDownIcon />}
                                  ref={anchorRef}
                                  aria-controls={open ? 'menu-list-grow' : undefined}
                                  aria-haspopup="true"
                                  onClick={handleToggle}
                                >Welcome: {userDetails?.first_name}</Button>

                                <Popper className="viewdropdown" open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                                  {({ TransitionProps, placement }) => (
                                    <Grow
                                      {...TransitionProps}
                                      style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                    >
                                      <Paper>
                                        <Box className="profile-bg">
                                          <Row >
                                            <Col md={3} xs={3} className="m-auto">
                                              <Box className="tlt-img-ico"><img src={userDetails && userDetails.image ? PROFILE_IMAGE + userDetails?.image : profileImg} ></img></Box>
                                            </Col>
                                            <Col md={9} xs={9}>
                                              <Box className="tlt-name">{userDetails && userDetails.agency_name}</Box>
                                              <Box className="tlt-id">ID: {userDetails && userDetails.uuid}</Box>
                                              
                                              <Box className="activedate">
                                                <NavLink to="/profile">
                                                  <p
                                                    color="default"
                                                    className="btnprofileset">View Profile</p>
                                                </NavLink>  
                                              </Box>
                                            </Col>
                                          
                                          </Row>
                                        </Box>
                                        <Box>
                                          <div className="balanced-view">
                                            <Box className="itemblc">
                                              <NavLink className="balance-nav" to="/top-up-recharge">
                                                <Box className="blnc-items-l">Balance</Box>
                                                <Box className="blnc-items-r balance-price">{!!userDetails && userDetails.balance}</Box>
                                              </NavLink>
                                            </Box>
                                            <Box className="itemblc">
                                              <Box className="blnc-items-l">Credit Limit</Box>
                                              <Box className="blnc-items-r">{!!userDetails && userDetails.credit_limit}</Box>
                                            </Box>
                                            <Box className="itemblc">
                                              <Box className="blnc-items-l">Credit Balance</Box>
                                              <Box className="blnc-items-r">{!!userDetails && userDetails.credit_balance}</Box>
                                            </Box>
                                          </div>
                                        
                                        </Box>
                                        <Box className="profilenChangespassword">
                                        
                                          <NavLink to="/change-password">
                                            <Button className="changebtn">Change Password</Button>
                                          </NavLink>
                                        </Box>
                                        

                                          <MenuList className="setlogout" autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                            <NavLink to="/login">
                                              <Button className="linklogout" onClick={handleClose}>Logout</Button>
                                            </NavLink>
                                          </MenuList>
                                          
                                      </Paper>
                                    </Grow>
                                  )}
                                </Popper>

                              </div>
                            </Box>
                          </Nav> 
                          </Navbar>
                        </>
                    </Box>
                  </ClickAwayListener>
                
              
             
            </Navbar>
          </Col>
        </Row>
      </Container>
      {/* ) : (<span>Loading...</span>)} */}
    </div>
  );
}

export default Header
