import React, { useEffect } from 'react';
import { Container } from '@mui/material';
import './user/index.css'
import './user/index'
import Accountheader from '../pages/user/Accountheader';
import withStyles from '@mui/styles/withStyles';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from "prop-types";
import { getAuth } from '../utility/apiConfig';
import { ACCESS_TOKEN, API_BASE_URL, REFRESH_TOKEN } from '../utility/apiConstants';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    disabledButton: {
        "&:disabled": {
            backgroundColor: "#1675b8",
            color: '#fff'
        }
    },
    linkfrg: {
        fontSize: 12,
        fontWeight: '500',
    },
});

export const LOADER = 'LOADER'
export const LOGIN = 'LOGIN'
export const ERROR = 'ERROR'

export const loader = () => {
    return {
      type: LOADER
    }
  }
  
  export const loginReqs = (data) => {
    return {
      type: LOGIN,
      payload: data
    }
  }
  
  export const errorReqs = (error) => {
    return {
      type: ERROR,
      payload: error
    }
  }


const LoginWithToken = (props) => {

    let history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        getAuthDetails();
    }, []);

    const getAuthDetails = () => {
        let data = window.location.href;
        let token = data.split('token=')[1];
        try {
            axios.get(`${API_BASE_URL}login/regenerate_tokens/`, {
                headers: {
                    Authorization: token //the token is a variable which holds the token
                }
            })
            .then((response) => {

                dispatch(loginReqs(response.data))
      
                if (response.data.status === true) {
                  if (response.data.type === 'login') {
                    localStorage.setItem(ACCESS_TOKEN, response.data.token.access_token)
                    localStorage.setItem(REFRESH_TOKEN, response.data.token.refresh_token)
                  }
      
                  try {
                    getAuth(API_BASE_URL + 'user/account', null)
                      .then((response) => {
                        if (response.data.status) {
                          localStorage.setItem('login_data', JSON.stringify(response.data))
                          history.push('/')
                        }
                      })
                  } catch (error) {
                    // dispatch(errorReqs(''))
                  }
                } else {
                  dispatch(errorReqs(response.data.message))
                }
              })
              .catch((error) => {
                if (error === 'Error: Network Error') {
                  dispatch(errorReqs('Network error please try again'))
                } else {
                  dispatch(errorReqs(error.response.data.message))
                }
              })
        } catch (error) {
            dispatch(errorReqs('Somthing went wrong please try again'))
        }
      }


    return <>
        <div className="account-header-bg">
            <Accountheader link="sign-up" title="Create an account" subTitle="Not yet registered?" />
        </div>
        <Container className="useraccountview logintokencontainer">
          <CircularProgress />
          <p className='mt-2'>Authenticating the user. Please wait while we redirect…</p>
        </Container>
    </>;
}

LoginWithToken.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(styles)(LoginWithToken)