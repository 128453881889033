import { Snackbar, SnackbarContent } from '@mui/material'
import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles';
import { Button } from 'react-bootstrap'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import OneWaySearchResults from '../../flights/flight-results/OneWaySearchResults';
import RoundTripSearchResults from '../../flights/flight-results/RoundTripSearchResults';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
    '& label': {
      margin: 0,
      marginBottom: 0
    },
    '& div': {
      margin: 0
    }
  },
  selectbx: {
    padding: 0,
    '&::before': {
      content: '',
      display: 'none'
    },
    '&::after': {
      content: '',
      display: 'none'
    }
  },

  rndsortbox: {
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex',
    alignContent: 'flex-start',
    justifyContent: 'space-between'
  },
  drpsort: {
    fontSize: 12,
    fontWeight: '500',
    textAlign: 'left',
    margin: "auto",
    width: "100%",
  },

  selectEmpty: {
    marginTop: theme.spacing(0)
  }
}))


const SearchResults = (props) => {
  const classes = useStyles()
  const [showFilterMob, setshowFilterMob] = useState(true)

  const toggleFilter = () =>{
    setshowFilterMob(!showFilterMob)
  }

  const [errorMsg, setErrorMsg] = useState(false)

  const handleCloseMsg = () => {
    setErrorMsg(false)
  }


  return (
        <>

            <Snackbar

                autoHideDuration={5000}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
                open={errorMsg}
                onClose={handleCloseMsg}
                message="The onward and return flights are overlapping with each other. Please change one of these selections"
                key={{
                  vertical: 'top',
                  horizontal: 'center'
                }}

            >

                <SnackbarContent style={{
                  backgroundColor: 'orange'
                }}
                    message={<span id="client-snackbar">The onward and return flights are overlapping with each other. Please change one of these selections</span>}
                />

            </Snackbar>

            {props.searchObj.trip_type === 'oneway' ?
             (<OneWaySearchResults />) :
             props.searchObj.trip_type === 'circle' ?
             (<RoundTripSearchResults />) :
             null}

            {/* <Container fluid="true" className="flightresultcontainer flightresultcontainer-round">
                <Row className="margin-bottomitem">
                    <Col xs={12} sm={12} md={3} lg={3} className="flitercolumstick filter-mob colspace" style={{ display: !showFilterMob ? "block" : "none" }}>
                        <Box className="filter-content">
                            <div className="tlt-filter-header">
                              <div className="tltrange-filter">Filter Search</div>
                              <div className="tltrangereset" onClick={handleReset}>Reset All</div>
                            </div>
                            <Airlinesfilter handleChange={onAirlinesfilterChange} airlineClear={clearAirline} isSelected={filterOnward.airline} />
                            <Pricerange handleRangChange={onPriceRageChange} onPrice={handleRangChange1} />
                            <div className="rtnbox">
                                <div className='tripheading'>Onward Journey</div>
                                <div className="separatebox">
                                    <Stops handleChange={onwardStopChange} isStops={filterOnward.stops} />
                                    <Timing title="Departure From " source={fsRoundReqData?.from} handleChange={onwardChangeDepFrom} isSelected={filterOnward.depatureTime} />
                                    <Timing title="Arrival At " source={fsRoundReqData?.to} handleChange={onwardChangeArrAt} isSelected={filterOnward.arrivalTime} />
                                </div>
                            </div>

                            <div className="rtnbox">
                                <div className='tripheading'>Return Journey</div>
                                <div className="separatebox">
                                    <Stops handleChange={returnStopChange} isStops={filterReturn.stops} />
                                    <Timing title="Departure From " source={fsRoundReqData?.to} handleChange={returnChangeDepFrom} isSelected={filterReturn.depatureTime} />
                                    <Timing title="Arrival At " source={fsRoundReqData?.from} handleChange={returnChangeArrAt} isSelected={filterReturn.arrivalTime} />
                                </div>
                            </div>

                            <Fareidentifier handleChange={onFareIdentifireChange} isSelected={filterOnward.fareIdentifire} filterType="fareIdentifier" filterTitle="Fare Identifier"/>
                            <Fareidentifier handleChange={onBookingSourceChange} isSelected={filterOnward.bookingSourc} filterType="bookingSources" filterTitle="Booking Sources" />
                       </Box>
                    </Col>
                    <Col xs={12} sm={12} md={9} lg={9} className="white-bg-xs roundtripresult-des">
                        <FlightListOneWay />
                        <FlightListRoundTrip />
                    </Col>
                </Row>
                
            </Container> */}
            <div>
              <div className='filter-btn-float'>
                <Button onClick={toggleFilter}><FilterAltIcon />Apply Filter</Button>
              </div>
            </div>
            

        </>
  )
}

export default SearchResults
