import React, { useEffect, useState } from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import {
  Tabs,
  Tab
} from '@mui/material'
import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles';
import {Typography, Button} from '@mui/material'
import Box from '@mui/material/Box'
import {
  FormControlLabel,
  MenuItem,
  Select
} from '@mui/material'
import './flights.css'
import OneWay from './OneWay'
import RoundTrip from './RoundTrip'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import HotelTopdeals from '../../top-deals-hotel/HotelTopdeals'
import FlightIcon from '@mui/icons-material/Flight';
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import moment from 'moment'
import FlightSearch from './FlightSearch'
import FlightListOneWay from './FlightListOneWay'
import SearchResults from './SearchResults'
import { airports } from '../../../services/airportList'
import { useDispatch } from 'react-redux'
import { flightSearchRequest } from '../../../store/flights/actions/flightSearchActions'

const FlightModal = (props) => {
  const dispatch = useDispatch()
  const [destination, setDestination] = useState({});
  const [showModify, setShowModify] = useState(false);
  const [fromAirport, setFromAirport] = useState([])
  const [fromValue, setFromValue] = useState(null)
  const [fromCountry, setFromCountry] = useState(null)
  const [toAirport, setToAirport] = useState([])
  const [toValue, setToValue] = useState(null)
  const [toCountry, setToCountry] = useState(null)
  const [isOpenDepart, setIsOpenDepart] = useState('')
  const [depDate, setDepDate] = useState()
  const [retDate, setRetDate] = useState(null)
  const [returnDate, setReturnDate] = useState('')
  const [isOpenReturn, setIsOpenReturn] = useState('')
  const [searchObj, setSearchObj] = useState({})

  useEffect(() => {
    setDestination(airports.filter(x=> x.airport_city === props.packageDetails.package.package_city)[0])
    setDepDate(moment(new Date()).add('days', 5).format('DD-MMM-yyyy'));
    setRetDate(moment(new Date()).add('days', Number(props?.packageDetails?.package?.duration)+5).format('DD-MMM-yyyy'));
    setFromAirport([...fromAirport, airports.filter(x=> x.airport_city === "Bangalore")[0]]);
    setFromValue(airports.filter(x=> x.airport_city === "Bangalore")[0]);
    setFromCountry(airports.filter(x=> x.airport_city === "Bangalore")[0].country)
    setToAirport([...toAirport, airports.filter(x=> x.airport_city === props.packageDetails.package.package_city)[0]]);
    setToValue(airports.filter(x=> x.airport_city === props.packageDetails.package.package_city)[0]);
    setToCountry(airports.filter(x=> x.airport_city === props.packageDetails.package.package_city)[0].country)
    setSearchObj({
      trip_type: 'circle',
      from: airports.filter(x=> x.airport_city === "Bangalore")[0]?.airport_city + ` (${airports.filter(x=> x.airport_city === "Bangalore")[0]?.airport_code})`,
      from_loc_id: airports.filter(x=> x.airport_city === "Bangalore")[0]?.origin,
      to: airports.filter(x=> x.airport_city === props.packageDetails.package.package_city)[0]?.airport_city + ` (${airports.filter(x=> x.airport_city === props.packageDetails.package.package_city)[0]?.airport_code})`,
      to_loc_id: airports.filter(x=> x.airport_city === props.packageDetails.package.package_city)[0]?.origin,
      depature: moment(new Date()).add('days', 5).format('DD-MMM-yyyy'),
      return: moment(new Date()).add('days', Number(props?.packageDetails?.package?.duration)+5).format('DD-MMM-yyyy'),
      adult: 1,
      child: 0,
      infant: 0,
      nonStop: false,
      studentFare: false,
      search_flight: 'search',
      v_class: 'Economy',
      carrier: [],
      fromCountry: airports.filter(x=> x.airport_city === "Bangalore")[0].country,
      toCountry: airports.filter(x=> x.airport_city === props.packageDetails.package.package_city)[0].country,
      specialFare: false,
      // booking_source: 'PTBSID0000000008',
      fareType: []
  
    })
}, [])

useEffect(() => {
  console.log("searchObj",searchObj)
  dispatch(flightSearchRequest(searchObj))
}, [searchObj])

  function dateFormat(date) {
    let formattedDate = moment(date).format('dddd Do MMMM YYYY');
    return formattedDate;
}

  return (
    <div>
      {!showModify ? (
        <Container className="search-box activity-flight">
        <Box >
          <form noValidate autoComplete="off">
            <Box className="search-wpr">
              <Grid container>
                <Grid item sm={9} xs={12} >
                  <Grid container spacing={1} className="cntrowswp">
                    <Grid item md={3} xs={12} className="prstColl mob-input-border">
                      <Box textAlign="left" className="box-tlt box-input-w">
                        <span>From</span>

                        <p>{fromAirport[0]?.airport_city + ` (${fromAirport[0]?.airport_code})`}</p>
                      </Box>

                    </Grid>

                    <Grid item md={1} xs={12} className="prstColl m-auto">
                      <Box className="box-swpicon-w">
                        <Box className="icoswipe swipebox" ><ArrowRightAltIcon /></Box>
                      </Box>
                    </Grid>

                    <Grid item md={4} xs={12} className="prstColl mob-input-border">
                      <Box textAlign="left" className="box-tlt box-input-w">
                        <span>To</span>
                        <p>{toAirport[0]?.airport_city + ` (${toAirport[0]?.airport_code})`}</p>
                      </Box>
                    </Grid>

                    <Grid item md={4} xs={12} className="prstColl d-flex mob-input-border">
                      <span className='border-right div-border-right'></span>
                      <Box textAlign="left" className="box-tlt box-input-w pl-4">
                        <span>Departure</span>
                        <p>{moment(depDate).format('Do MMMM YYYY, ddd')}</p>
                        {/* <p>12 March, 2023, Wed</p> */}
                      </Box>
                    </Grid>
                  </Grid>
                  {searchObj.trip_type !== 'oneway' && (
                  <Grid container spacing={1} className="cntrowswp">
                    <Grid item md={3} xs={12} className="prstColl mob-input-border">
                      <Box textAlign="left" className="box-tlt box-input-w">
                        <span>From</span>
                        <p>{toAirport[0]?.airport_city + ` (${toAirport[0]?.airport_code})`}</p>

                      </Box>

                    </Grid>

                    <Grid item md={1} xs={12} className="prstColl m-auto">
                      <Box className="box-swpicon-w">
                        <Box className="icoswipe swipebox" ><ArrowRightAltIcon /></Box>
                      </Box>
                    </Grid>

                    <Grid item md={4} xs={12} className="prstColl mob-input-border">
                      <Box textAlign="left" className="box-tlt box-input-w">
                        <span>To</span>
                        <p>{fromAirport[0]?.airport_city + ` (${fromAirport[0]?.airport_code})`}</p>

                      </Box>
                    </Grid>

                    <Grid item md={4} xs={12} className="prstColl d-flex mob-input-border">
                      <span className='border-right div-border-right'></span>
                      <Box textAlign="left" className="box-tlt box-input-w pl-4">
                        <span>Return</span>

                        <p>{moment(retDate).format('Do MMMM YYYY, ddd')}</p>
                      </Box>
                    </Grid>
                  </Grid>
                  )}
                </Grid>
              
             
                <Grid item sm={3} xs={12} className="m-auto">
                  <Button
                    variant="contained"
                    color="primary"
                    className="btnSend"
                    onClick={()=> setShowModify(true)}
                  >
                    Modify
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Box>
        </Container>
      ) : (
          <FlightSearch
            showModify={showModify}
            setShowModify={setShowModify}
            destination={destination}
            setDestination={setDestination}
            packageDetails={props.packageDetails}
            fromAirport={fromAirport}
            setFromAirport={setFromAirport}
            fromValue={fromValue}
            setFromValue={setFromValue}
            fromCountry={fromCountry}
            setFromCountry={setFromCountry}
            toAirport={toAirport}
            setToAirport={setToAirport}
            toValue={toValue}
            setToValue={setToValue}
            toCountry={toCountry}
            setToCountry={setToCountry}
            depDate={depDate}
            setDepDate={setDepDate}
            retDate={retDate}
            setRetDate={setRetDate}
            searchObj={searchObj}
            setSearchObj={setSearchObj}
          />
      )}
        <SearchResults 
          searchObj={searchObj}
          />

        
    </div>
  );
}

export default FlightModal
