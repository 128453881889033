import React, { useEffect } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'
import { useDispatch } from 'react-redux'

const Shownet = (props) => {
  const { onShowNetChange } = props

  const dispatch = useDispatch()

  const [state, setState] = React.useState({
    checkedA: false
  })

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked })
    onShowNetChange(event.target.checked)
    // dispatch(showNet(event.target.checked))
  }

  useEffect(() => {

  }, [props])

  return (
        <div className="checkBoxshownet">
        {/* <Box className="tlt-heading"></Box> */}
        {/* <Box className="tlt-heading">Show Net Price</Box> */}

        <FormControlLabel className="check-setbox"
            control={<Checkbox checked={props.isChecked} onChange={handleChange} name="checkedA" />}
            label="Show Net Price"
        />
        </div>
  )
}

export default Shownet
