import { getAuth } from "../../../utility/apiConfig";
import { API_BASE_URL } from "../../../utility/apiConstants";

export const getPackageType = async (eventNumber) => {
    const response = await getAuth(API_BASE_URL + `tours/search/?package_type=${eventNumber}`);
    return response;
};

export const getPackagesList = async (eventUrl) => {
    const response = await getAuth(API_BASE_URL + `tours/search?${eventUrl}`);
    return response;
};

export const getPackageDetails = async (package_id) => {
    const response = await getAuth(API_BASE_URL + `tours/package_details/${package_id}`);
    return response;
};

export const getPackageCountry = async (eventNumber) => {
    const response = await getAuth(API_BASE_URL + `tours/search_country/?package_type=${eventNumber}`);
    return response;
};