import React, { useEffect, useState } from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import {
  Tabs,
  Tab
} from '@mui/material'
import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles';
import {Typography, Button} from '@mui/material'
import Box from '@mui/material/Box'

import './flights.css'
import OneWay from './OneWay'
import RoundTrip from './RoundTrip'
import FlightIcon from '@mui/icons-material/Flight';
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import moment from 'moment'
import { airports } from '../../../services/airportList'

const FlightSearch = (props) => {
  const [checked, setChecked] = useState(true);
  const [tripError, setTripError] = useState({
    fromError: '',
    toError: '',
    depatureError: '',
    returnError: ''
  })
  const [trip, setTrip] = useState({
    from: '',
    from_loc_id: '',
    to: '',
    to_loc_id: '',
    depature: '',
    return: ''
  })
  // const [tripType, setTripType] = useState('oneway')
  const [oneWay, setOneWay] = useState({
    trip_type: 'oneway',
    from: '',
    from_loc_id: '',
    to: '',
    to_loc_id: '',
    depature: '',
    adult: 1,
    child: 0,
    infant: 0,
    nonStop: false,
    studentFare: false,
    search_flight: 'search',
    v_class: 'Economy',
    carrier: [],
    specialFare: false,
    rating: [],
    fareType: [],
  })
  const [fromAirport, setFromAirport] = useState([])
  const [fromValue, setFromValue] = useState(null)
  const [toAirport, setToAirport] = useState([])
  const [toValue, setToValue] = useState(null)
  const [isOpenDepart, setIsOpenDepart] = useState('')
  const [depDate, setDepDate] = useState()
  const [retDate, setRetDate] = useState(null)
  const [returnDate, setReturnDate] = useState('')
  const [isOpenReturn, setIsOpenReturn] = useState('')
  const [fromCountry, setFromCountry] = useState(null)
  const [toCountry, setToCountry] = useState(null)

  useEffect(() => {
   // setDestination(airports.filter(x=> x.airport_city === props.packageDetails.package.package_city)[0])
    setDepDate(moment(props.depDate).format('DD-MMM-yyyy'));
    setRetDate(moment(props.retDate).format('DD-MMM-yyyy'));
    setFromAirport(props.fromAirport);
    setFromValue(props.fromValue);
    setFromCountry(props.fromCountry)
    setToAirport(props.toAirport);
    setToValue(props.toValue);
    setToCountry(props.toCountry);
    setTrip({...trip,
        from: props.fromAirport[0]?.airport_city + ` (${props.fromAirport[0]?.airport_code})`,
        from_loc_id: props.fromAirport[0]?.origin,
        to: props.toAirport[0]?.airport_city + ` (${props.toAirport[0]?.airport_code})`,
        to_loc_id: props.toAirport[0]?.origin,
        depature: moment(props.depDate).format('DD-MMM-yyyy'),
        return: moment(props.retDate).format('DD-MMM-yyyy')
      })
}, [])

  function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`nav-tabpanel-${index}`}
        aria-labelledby={`nav-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  }

  function flightTabProps(index) {
    return {
      id: `nav-tab-${index}`,
      'aria-controls': `nav-tabpanel-${index}`
    }
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper
    }
  }))
  const classes = useStyles()

  const [flightSearchType, setFlightSearchType] = useState(1)

  const handleFlightSearchType = (event, type) => {
    setFlightSearchType(type)
  }
  

  return (
    <div>
        <Container className="search-box activity-flight">
            <Grid container spacing={0}>
            <Grid
                item
                lg={12}
                md={12}
                xs={12}
                spacing={0}
            >
                <Box pt={0}>
                <Tabs
                    border={1}
                    variant="fullWidth"
                    indicatorColor="primary"
                    centered
                    textColor="primary"
                    color="primary"
                    value={flightSearchType}
                    onChange={handleFlightSearchType}
                    aria-label="nav tabs example"
                    spacing={0}
                    className="bg-white searchtab-main"
                >
                    <Tab
                    className="tabTilte"
                    variant="contained"
                    label="Oneway"
                    icon={<FlightIcon />}
                    {...flightTabProps(0)}
                    style={{
                        borderTopLeftRadius: 0
                    }}
                    />
                    <Tab
                    className="tabTilte"
                    label="Round Trip"
                    icon={<ConnectingAirportsIcon className='roundair-icon' />}
                    {...flightTabProps(1)}
                    style={{
                        borderLeft: '1px solid #ccc',
                        borderRight: '1px solid #ccc',
                        borderBottom: '1px solid #ccc'
                    }}
                    />
                </Tabs>

                <TabPanel className="tabsPanelbdr bg-white" value={flightSearchType} index={0}>
                    <OneWay
                    tripError={tripError}
                    setTripError={setTripError}
                    trip={trip}
                    setTrip={setTrip}
                    fromAirport={fromAirport}
                    setFromAirport={setFromAirport}
                    setPropsFromAirport={props.setFromAirport}
                    fromValue={fromValue}
                    setFromValue={setFromValue}
                    setPropsFromValue={props.setFromValue}
                    toAirport={toAirport}
                    setToAirport={setToAirport}
                    setPropsToAirport={props.setToAirport}
                    toValue={toValue}
                    setToValue={setToValue}
                    setPropsToValue={props.setToValue}
                    isOpenDepart={isOpenDepart}
                    setIsOpenDepart={setIsOpenDepart}
                    depDate={depDate}
                    setDepDate={setDepDate}
                    setPropsDepDate={props.setDepDate}
                    fromCountry={fromCountry}
                    setFromCountry={setFromCountry}
                    setPropsFromCountry={props.setFromCountry}
                    toCountry={toCountry}
                    setToCountry={setToCountry}
                    setPropsToCountry={props.setToCountry}
                    showModify={props.showModify}
                    setShowModify={props.setShowModify}
                    searchObj={props.searchObj}
                    setSearchObj={props.setSearchObj} 
                    />
                </TabPanel>

                <TabPanel className="tabsPanelbdr bg-white" value={flightSearchType} index={1}>
                    <RoundTrip
                    tripError={tripError}
                    setTripError={setTripError}
                    trip={trip}
                    setTrip={setTrip}
                    fromAirport={fromAirport}
                    setFromAirport={setFromAirport}
                    setPropsFromAirport={props.setFromAirport}
                    fromValue={fromValue}
                    setFromValue={setFromValue}
                    setPropsFromValue={props.setFromValue}
                    toAirport={toAirport}
                    setToAirport={setToAirport}
                    setPropsToAirport={props.setToAirport}
                    toValue={toValue}
                    setToValue={setToValue}
                    setPropsToValue={props.setToValue}
                    isOpenDepart={isOpenDepart}
                    setIsOpenDepart={setIsOpenDepart}
                    depDate={depDate}
                    setDepDate={setDepDate}
                    setPropsDepDate={props.setDepDate}
                    retDate={retDate}
                    setRetDate={setRetDate}
                    setPropsRetDate={props.setRetDate}
                    isOpenReturn={isOpenReturn}
                    setIsOpenReturn={setIsOpenReturn}
                    returnDate={returnDate}
                    setReturnDate={setReturnDate}
                    fromCountry={fromCountry}
                    setFromCountry={setFromCountry}
                    setPropsFromCountry={props.setFromCountry}
                    toCountry={toCountry}
                    setToCountry={setToCountry}
                    setPropsToCountry={props.setToCountry}
                    showModify={props.showModify}
                    setShowModify={props.setShowModify}
                    searchObj={props.searchObj}
                    setSearchObj={props.setSearchObj}
                    />
                </TabPanel>

                </Box>
            </Grid>
            </Grid>
      </Container>
    </div>
  );
}

export default FlightSearch
