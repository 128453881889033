import React, { useEffect, useState } from 'react'
import {
    Tooltip, Accordion, AccordionSummary, AccordionDetails,
    Box, Button, Link, Tab, Tabs, Grid, ButtonGroup, Typography, TextField,DialogContentText
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Col, Container, Row, } from 'react-bootstrap'
import star from '../../../assets/images/star.svg'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DoneIcon from '@mui/icons-material/Done';
import CheckIcon from '@mui/icons-material/Check';
import PropTypes from 'prop-types'
import './selectactivity.css'
import '../activity-results/activityresult.css'
import withStyles from '@mui/styles/withStyles';
import Dialog from '@mui/material/Dialog'
import MuiDialogTitle from '@mui/material/DialogTitle'
import MuiDialogContent from '@mui/material/DialogContent'
import MuiDialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import DateFnsUtils from '@date-io/date-fns'
import Modal from 'react-bootstrap/Modal';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers'
import { useHistory } from 'react-router-dom'
import ClearIcon from '@mui/icons-material/Clear';
import ActivityPopupImg from '../activity-results/ActivityPopupImg'
import HotelIcon from '@mui/icons-material/Hotel';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { getPackageDetails } from '../activity-results/IndexApi';
import ReactHtmlParser from 'react-html-parser'
import MuiAlert from '@mui/material/Alert';
import FlightModal from '../activity-flights/FlightModal';
import img from '../../../assets/images/hotelicoimg.jpeg'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ActivityBookingModal from '../activity-booking/ActivityBookingModal';
import Accountheader from '../../user/Accountheader';
import Header from '../../header/Header';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';

const faqDetails = [
    {
        id: 1,
        question: "What is the best time to visit Leh?",
        answer: "The best time to visit Leh is from April to September when the weather is relatively mild and the roads are accessible."
    },
    {
        id: 2,
        question: "What are the top tourist attractions in Leh?",
        answer: "Some of the top tourist attractions in Leh include the Leh Palace, Shanti Stupa, Nubra Valley, Pangong Tso Lake, and Hemis National Park."
    },
    {
        id: 3,
        question: "What kind of clothing should I pack for a Leh trip?",
        answer: "You should pack warm clothing for a Leh trip, including jackets, sweaters, and thermals, as the temperature can drop significantly at night. You should also pack comfortable shoes and clothing for trekking or hiking."
    },
    {
        id: 4,
        question: "Do I need a special permit to visit Leh?",
        answer: "Yes, tourists need a special Inner Line Permit (ILP) to visit certain areas of Leh, including Nubra Valley, Pangong Tso Lake, and Tso Moriri Lake. The permit can be obtained online or through authorized travel agents."
    },
    {
        id: 5,
        question: "What is the currency used in Leh?",
        answer: "The currency used in Leh is the Indian Rupee (INR)."
    },
    {
        id: 6,
        question: "Is it safe to travel to Leh?",
        answer: "Yes, Leh is generally considered safe for tourists. However, it's important to take precautions and follow safety guidelines, especially when trekking or hiking in remote areas. It's also important to be aware of the altitude sickness and take necessary precautions to avoid it."
    }
]

//document.title = "Leh-Ladakh Escapade: A Himalayan Journey - B2BTA.COM"

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
})

function TabPanel(props) {
    const { children, value, index } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
        >
            {value === index && (
                <Box className="tabboxhotel">
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
}

function ActivityTabPanel(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`
    }
}

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose
                ? (
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}
                        size="large">
                        <CloseIcon />
                    </IconButton>
                )
                : null}
        </MuiDialogTitle>
    );
})

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions)

const ActivityDetails = () => {
    const userDetails = localStorage.getItem('login_data')
    const history = useHistory()
    const currentURL = window.location.href;
    var package_id = currentURL.split("?")[1];
    const [value, setValue] = useState(2)
    const [advanceShow, setAdvanceShow] = useState(false)
    const [open, setOpen] = useState(false);
    const [allInitialEvents, setAllInitialEvents] = useState([]);
    const [packageDetails, setPackageDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [adultQuantity, setAdultQuantity] = useState(2);
    const [childQuantity, setChildQuantity] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [showMore, setShowMore] = useState(true);
    const [modal, setModal] = useState(false);
    const [expanded, setExpanded] = useState('panel1');
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [travelDate, setTravelDate] = useState("2023/04/16");
    const [searchText, setSearchText] = useState("");
    const [initialFaqData, setInitialFaqData] = useState(faqDetails);
    const [faqData, setFaqData] = useState(faqDetails);
    const [modalFlight, setModalFlight] = useState(false);
    const [selectedPricing, setSelectedPricing] = useState(null);

    const handleModalFlightOpen = () => setModalFlight(true);
    const handleModalFlightClose = () => setModalFlight(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const [modalBook, setModalBook] = useState(false);

    const handleModalBookOpen = () => setModalBook(true);
    const handleModalBookClose = () => setModalBook(false);
    const [openDialog, setOpenDialog] = React.useState(false);

    const handleClickOpenDialog = () => {
      setOpenDialog(true);
    };
  
    const handleCloseDialog = () => {
      setOpenDialog(false);
    };
    const handleClickPopOver = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopOver = () => {
        setAnchorEl(null);
    };

    const openPopOver = Boolean(anchorEl);
    const id = openPopOver ? 'simple-popover' : undefined;


    useEffect(() => {
        loadActivityDetails()
    }, [])

    useEffect(() => {
        loadTotalPrice()
    }, [adultQuantity])

    const loadActivityDetails = async () => {
        setIsLoading(true);
        try {
            await getPackageDetails(package_id)
                .then((response) => {
                    let data = response.data.data
                    // console.log("data", data)
                    setPackageDetails(data);
                    setAllInitialEvents(data);
                    setTotalPrice(Number(data?.package?.price) * 1)
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log("Error", error);
                    setIsLoading(false);
                })
        } catch (error) {
            setIsLoading(false);
        }
    }


    const handleModalOpen = () => setModal(true);
    const handleModalClose = () => setModal(false);

    const advanceToggle = () => {
        setAdvanceShow(!advanceShow)
    }

    const handleTabinChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleClickOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }



    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const MuiAccordionSummaryFAQ = styled((props) => (
        <MuiAccordionSummary
            expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
            {...props}
        />
    ))(({ theme }) => ({

        flexDirection: 'row-reverse',
        '& .faq-section-aacordian .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .faq-section-aacordian .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
        },
    }));

    const onChangeAdultQuantity = (type) => {
        if (type === "add") {
            setAdultQuantity(adultQuantity + 1)
        }
        else if (type === "subtract" && adultQuantity > 2) {
            setAdultQuantity(adultQuantity - 1)
        }
    }

    const onChangeChildQuantity = (type) => {
        if (type === "add") {
            setChildQuantity(childQuantity + 1)
        }
        else if (type === "subtract" && childQuantity > 0) {
            setChildQuantity(childQuantity - 1)
        }
    }

    const loadTotalPrice = () => {
        setTotalPrice(Number(packageDetails?.package?.price) * adultQuantity)
    }

    const handlePricing = (value) => {
        setSelectedPricing(value);
    }

    const handleSearch = (e) => {
        let text = e.target.value;
        setSearchText(text);
        if (text !== '') {
            const filteredArr = initialFaqData?.filter((item) => {
                return (item.question.toLowerCase().indexOf(text.toLowerCase()) > -1 || item.answer.toLowerCase().indexOf(text.toLowerCase()) > -1)
            })
            setFaqData(filteredArr)
        }
        else {
            setFaqData(initialFaqData);
        }
    }

    const handleContinue = () => {
        if (selectedPricing === null) {
            // window.alert("Please select any one package")
            setOpenDialog(true);
        }
        else {
            handleModalBookOpen()
        }
    }
    const scroll_click = () => {
        setOpenDialog(false);
        document.querySelector(`#scroll_div`).scrollIntoView();
        setValue(2)
     }
    return <>
        <div className="Main-content-box">

            {userDetails === null ? (
                <div className="account-header-bg">
                    <Accountheader link="sign-up" title="Create an account" subTitle="Not yet registered?" />
                </div>
            ) : (
                <div className="Main-content-header">
                    <Header />
                </div>

            )}
            <Box className="searchbar-content ">
                <Container >
                    <Col xs={12} md={12} className="px-0">
                        <div class="headerWrapper-input ">
                            <div className='headerWrapper-left-1 '>
                                <h1 className='text-white pt-3'>Leh-Ladakh Escapade: A Himalayan Journey</h1>
                            </div>
                        </div>
                    </Col>

                </Container>
            </Box>
            <Container className='selectactivity-container activity-details margin pt-1'>
                {/* {packageDetails.length === 0 ? (
            <ActivityDetailsSkltn />
            ) : ( */}
                <Row>
                    <Col xs={12} sm={12} md={8} lg={8} className="colspace act-select-wrapper ">
                        <Row className="tabsrow">
                            <Col xs={12} sm={12} md={12}>
                                <Box className="tlt-hotel-1">Leh-Ladakh Escapade: A Himalayan Journey - 6N / 7D</Box>
                                <Box className="tlt-hotel-2">Leh, India</Box>

                            </Col>
                            {/* {packageDetails?.package_traveller_photos?.length > 0 && ( */}
                            <Col xs={7} sm={8} className="colspace ">
                                <div className="singleimgbox"><img src='https://itwgo.in/b2btravelagentAPI/extras/custom/TMX1512291534825461/images/leh/shanti-stupta-leh1.jpg' alt="Leh Image 1"></img></div>
                            </Col>
                            {/* )} */}
                            <Col xs={5} sm={4} className="colspace pl-0">
                                {/* {packageDetails?.package_traveller_photos?.length > 1 && ( */}
                                <Row>
                                    <Col xs={12} sm={12} >
                                        <div className="multiimgbox"><img src='https://itwgo.in/b2btravelagentAPI/extras/custom/TMX1512291534825461/images/leh/Turtuk-Travel-Guide-10.jpg' alt="Leh Image 2"></img></div>
                                    </Col>
                                </Row>
                                {/* )} */}
                                <Row >
                                    {/* {packageDetails?.package_traveller_photos?.length > 2 && ( */}
                                    <Col xs={6} sm={6} >
                                        <div className="sideimgboxleft"><img src='https://itwgo.in/b2btravelagentAPI/extras/custom/TMX1512291534825461/images/leh/image-6.jpg' alt="Leh Image 3"></img></div>
                                    </Col>
                                    {/* )} */}
                                    {/* {packageDetails?.package_traveller_photos?.length > 3 && ( */}
                                    <Col xs={6} sm={6} id="scroll_div">
                                        <div className="sideimgboxright">
                                            <img src='https://itwgo.in/b2btravelagentAPI/extras/custom/TMX1512291534825461/images/leh/download (13).jpeg' alt="Leh Image 4"></img>
                                        </div>
                                    </Col>
                                    {/* )} */}
                                    <Col xs={12} sm={12} md={12} lg={12} >
                                        <Box sx={{ position: 'relative' }}>
                                            <Button className='view-images-btn' onClick={handleModalOpen}>View All</Button>
                                   
                                            <Modal className='images-modal' show={modal} onHide={handleModalClose}>
                                                <Modal.Body>
                                                        <div className='modal-btn container'>
                                                            <Button className='close-modal-btn' onClick={handleModalClose}>Close</Button>
                                                            <ActivityPopupImg />
                                                        </div>
                                                </Modal.Body>
                                            </Modal>
                                        </Box>

                                        {/*     */}
                                    </Col>
                                </Row>
                            </Col>

                        </Row>

                        <Row className="tabsrow activity-tab" id="rooms">
                            <Col xs={12} sm={12} md={12} lg={12} className="coltabspace">
                                <Tabs
                                    className="tabswdth"
                                    value={value}
                                    onChange={handleTabinChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                    aria-label="full width tabs example"
                                >

                                    <Tab className="hoteltab" label="Overview" {...ActivityTabPanel(0)} />
                                    <Tab className="hoteltab" label="Itinenary" {...ActivityTabPanel(1)} />
                                    <Tab className="hoteltab" label="Pricing" {...ActivityTabPanel(2)} />
                                    {/* <Tab className="hoteltab" label="Meals" {...ActivityTabPanel(3)} /> */}
                                    <Tab className="hoteltab" label="Location" {...ActivityTabPanel(3)} />


                                </Tabs>

                                <TabPanel value={value} index={0}>
                                    <div className="hoteladdress">
                                        <Box>
                                            "Leh-Ladakh Escapade: A Himalayan Journey" is a holiday package that offers travelers an opportunity to explore the rugged landscapes and rich cultural heritage of the Leh-Ladakh region in the Indian Himalayas. The package typically includes a mix of cultural experiences and scenic drives through the stunning landscapes of the region.
                                        </Box>
                                    </div>
                                </TabPanel>

                                <TabPanel value={value} index={1}>
                                    <div className="hoteladdress itinenary-list">
                                        <Accordion >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography>Day 1 : Leh  (Visit Shanti Stupa and Leh Palace in Evening).</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    <ul className='inclution-list-simple px-4'>
                                                        <li><DoneIcon className='refreshmentIcon mr-2' />
                                                            <span>
                                                            Experience a wonderful flight over the Himalayas. On arrival, you will be received by a ITWgo Expeditions representative and transferred to the hotel. After welcome tea and coffee, rest for the whole day & and let your body take complete acclimatization. Late afternoon, if health permits, drive to Shanti Stupa &amp; stay back. Overnight Hotel.
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>

                                        <Accordion
                                        //    expanded={expanded === 'panel1'} onChange={handleChange('panel1')}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography>Day 2 : Hall Of Fame / Magnetic Hill / Gurudwara/ Sangam Point</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    <ul className='inclution-list-simple px-4'>
                                                        <li><DoneIcon className='refreshmentIcon mr-2' />
                                                            <span>
                                                            Hall Of Fame / Magnetic Hill / Gurudwara/ Sangam Point Breakfast at the hotel. After breakfast, we take you for a tour of the Hall of Fame, GurudwaraPatthar Sahib, Magnetic Hill, Kali Temple, and Sangam. At Sangam, you can enjoy rafting on Zangskarriver. Hall of Fame is a must-visit for every patriotic Indian. It has been built by the Indian army in memory of soldiers who lost their lives in the Kargil war. Return to Leh in the afternoon.
                                                            </span>
                                                        </li>
                                                        <li><DoneIcon className='refreshmentIcon mr-2' />
                                                            <span>
                                                            GurdwaraPatthar Sahib located at a distance of 40 km from Leh, was constructed in 1571, in memory of Guru Nanak to commemorate his visit to this region.
                                                            </span>
                                                        </li>
                                                        <li><DoneIcon className='refreshmentIcon mr-2' />
                                                            <span>
                                                            Magnetic Hill is the place where you will see your car, with its engine turned off, move upwards on a steep slope. It almost sounds unbelievable.
                                                            </span>
                                                        </li>
                                                        <li><DoneIcon className='refreshmentIcon mr-2' />
                                                            <span>
                                                            The Sangam is the place called Nimu where the Zanskar river and Indus rivers merge. In the evening come back to Leh. Overnight Hotel.
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>

                                        <Accordion >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography>Day 3 : Leh /Nubra Valley via Khardongla. 125km.</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    <ul className='inclution-list-simple px-4'>
                                                        <li><DoneIcon className='refreshmentIcon mr-2' />
                                                            <span>
                                                                After an early breakfast, drive to beautiful Nubra valley via Khardungla (The highest Motorable road in the World, 18,380 ft) with the zig zag trail continues and drive till Hundar village. On arrival check in at Nature&#39;s nest North camp. Afternoon enjoys the beauty of dune and double hump camel. Overnight at Camp or hotel.
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>

                                        <Accordion >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography>Day 4 : Turtuk Excursion</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    <ul className='inclution-list-simple px-4'>
                                                        <li><DoneIcon className='refreshmentIcon mr-2' />
                                                            <span>
                                                            Early morning drive to Turtuk village with drive landscape, Exotic village. Turtuk this village is about 115 km from Nubra valley and can take about 3 to 4 hours to reach here, the total population of Turtuk is just 4000, and follow Islam as about 40 years back this village was under Pakistan and speak the Baltistan language, explore this beautiful village and explore the life here, we will go for a walk around the village and also meet the people. Later Come back to Hunder Village. Overnight at the camps or Hotel.
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>

                                        <Accordion >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography>Day 5 : Disket/ Hunder/ Pangong Lake (Via Shayok)</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    <ul className='inclution-list-simple px-4'>
                                                        <li><DoneIcon className='refreshmentIcon mr-2' />
                                                            <span>
                                                            Today, we get up early morning visit to Diskit monastery, and have a wonderful view of Nubra valley. Later drive to Pangong Lake via Shayok.
                                                            </span>
                                                        </li>
                                                        <li><DoneIcon className='refreshmentIcon mr-2' />
                                                            <span>
                                                            Enjoy the turquoise color panoramic lake and the magnificent mountains and landscapes which will leave you awestruck. Overnight at Camp.
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>

                                        <Accordion >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography>Day 6 : Pangong /Leh</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    <ul className='inclution-list-simple px-4'>
                                                        <li><DoneIcon className='refreshmentIcon mr-2' />
                                                            <span>
                                                            After a late breakfast, we stroll in and around the lake and watch mother nature soothe you. Later drive back to Leh. Enroute visit Thiksey Monastery and Shey palace on the way. Overnight Hotel.
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>

                                        <Accordion >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography>Day 7 : Departure: Leh/ Delhi</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    <ul className='inclution-list-simple px-4'>
                                                        <li><DoneIcon className='refreshmentIcon mr-2' />
                                                            <span>
                                                            After an early breakfast, depart to the airport for your onward destination ….fly with sweet memories of Ladakh.
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                </TabPanel>

                                <TabPanel value={value} index={2}>
                                    {packageDetails?.package_similar_hotels?.length > 0 && <h5>Hotel</h5>}
                                    {packageDetails?.package_similar_hotels?.length > 0 && packageDetails?.package_similar_hotels?.map(hotel =>
                                        <Box className="cancellation d-flex mt-3 mb-3">
                                            <Tooltip title="Hotel Included" arrow><span className='mr-3'><HotelIcon /></span></Tooltip>
                                            <Typography>{hotel.hotel_star_category} Star <b>{hotel.hotel_name}</b> or Similar</Typography>
                                        </Box>
                                    )}

                                    <Row className='roomdetailsbookingbox'>
                                        <Col xs={12} sm={12} md={3}>
                                            <div className="roombox">
                                                <div className="roomdtlstlt">Package type: <b>Deluxe</b></div>

                                                <div className="viewdtlshotelcls">
                                                    <Link color="inherit" className="pointercls" onClick={() => handleClickOpen()}>
                                                        View cancellation policy
                                                    </Link>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col xs={12} sm={12} md={9} className="border-left">
                                            <Row className='hd-border-top'>
                                                <Col xs={12} sm={12}>
                                                    <Row className='hd-border-top'>
                                                        <Col xs={8} sm={9}>
                                                            <div className='heading-hd mt-3'>
                                                                <span>Option 1: </span><h5>Premium Leh-Ladakh Tour</h5>
                                                                <h6>Breakfast & Lunch / Dinner (MAP)</h6>
                                                            </div>
                                                        </Col>
                                                        <Col xs={4} sm={3} className="m-auto text-right">
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                size="small"
                                                                className={selectedPricing === "option_1" ? 'btn btn-primary btn-selected-border-radius' : 'btn btn-primary btn-disabled-border-radius'}
                                                                onClick={() => handlePricing("option_1")}
                                                            >
                                                                {selectedPricing === "option_1" ? "Selected" : "Select"}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    <div className="cancellationboxhotel">
                                                        <Box className="cancellation d-flex  mb-3">
                                                            <Tooltip title="Hotel Included" arrow><span className='mr-3'><HotelIcon /></span></Tooltip>
                                                            <Typography>LEH (3 Nights) at <b>Hotel Royal Ladakh</b> <img src={star} alt="star" className="starico"></img><img src={star} alt="star" className="starico"></img><img src={star} alt="star" className="starico"></img></Typography>
                                                        </Box>
                                                        <Box className="cancellation d-flex mt-2 mb-3">
                                                            <Tooltip title="Hotel Included" arrow><span className='mr-3'><HotelIcon /></span></Tooltip>
                                                            <Typography>NUBRA (2 Nights) at <b>Hotel Snow Leopard</b> <img src={star} alt="star" className="starico"></img><img src={star} alt="star" className="starico"></img><img src={star} alt="star" className="starico"></img></Typography>
                                                        </Box>
                                                        <Box className="cancellation d-flex mt-2 mb-3">
                                                            <Tooltip title="Hotel Included" arrow><span className='mr-3'><HotelIcon /></span></Tooltip>
                                                            <Typography>PANGONG (1 Nights) at <b>Pangong Vista Resorts</b> <img src={star} alt="star" className="starico"></img><img src={star} alt="star" className="starico"></img><img src={star} alt="star" className="starico"></img></Typography>
                                                        </Box>
                                                    </div>
                                                </Col>

                                                <Col xs={12} className="consting-list">
                                                    <h5>Costing :</h5>
                                                    <h6>No of Pax and Cars <span className='text-right'>Rate in INR Per Person</span></h6>
                                                    <ul>
                                                        <li className='costing_li'>For 02 Pax with 01 NAC Innova Car <span><b className='text-right'>&#x20B9; 56,350</b></span></li>
                                                        <li className='costing_li'>For 04 Pax with 01 NAC Innova Car  <span><b className='text-right'>&#x20B9; 44,505</b></span></li>
                                                        <li className='costing_li'>For 06 Pax with 02 NAC Innova Car  <span><b className='text-right'>&#x20B9; 48,530</b></span></li>
                                                        <li className='costing_li'>For 06 Pax with 01 NAC Tempo  <span><b className='text-right'>&#x20B9; 42,550</b></span></li>
                                                        <li className='costing_li'>For 08 Pax with 01 NAC Tempo  <span><b className='text-right'>&#x20B9; 40,020</b></span></li>
                                                        <li className='costing_li'>For 10 Pax with 01 NAC Tempo  <span><b className='text-right'>&#x20B9; 38,525</b></span></li>
                                                        <li className='costing_li'>For 12 Pax with 01 NAC Tempoo  <span><b className='text-right'>&#x20B9; 37,375</b></span></li>
                                                        <li className='costing_li'>Extra Bed  <span><b className='text-right'>&#x20B9; 30,475</b></span></li>
                                                        <li className='costing_li'>Child Without Extra Bed  <span><b className='text-right'>&#x20B9; 25,990</b></span></li>

                                                    </ul>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row className='roomdetailsbookingbox'>
                                        <Col xs={12} sm={12} md={3}>
                                            <div className="roombox">
                                                <div className="roomdtlstlt">Package type: <b>Luxury</b></div>

                                                <div className="viewdtlshotelcls">
                                                    <Link color="inherit" className="pointercls" onClick={() => handleClickOpen()}>
                                                        View cancellation policy
                                                    </Link>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={9} className="border-left">
                                            <Row className='hd-border-top'>
                                                <Col xs={12} sm={12}>
                                                    <Row className='hd-border-top'>
                                                        <Col xs={8} sm={9}>
                                                            <div className='heading-hd mt-3'>
                                                                <span>Option 2: </span><h5>Luxury Leh-Ladakh Tour</h5>
                                                                <h6>Breakfast & Lunch / Dinner (MAP)</h6>
                                                            </div>
                                                        </Col>
                                                        <Col xs={4} sm={3} className="m-auto text-right">
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                size="small"
                                                                className={selectedPricing === "option_2" ? 'btn btn-primary btn-selected-border-radius' : 'btn btn-primary btn-disabled-border-radius'}
                                                                onClick={() => handlePricing("option_2")}
                                                            >
                                                                {selectedPricing === "option_2" ? "Selected" : "Select"}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    <div className="cancellationboxhotel">
                                                        <Box className="cancellation d-flex  mb-3">
                                                            <Tooltip title="Hotel Included" arrow><span className='mr-3'><HotelIcon /></span></Tooltip>
                                                            <Typography>LEH (3 Nights) at <b>The Grand Dragon</b> <img src={star} alt="star" className="starico"></img><img src={star} alt="star" className="starico"></img><img src={star} alt="star" className="starico"></img><img src={star} alt="star" className="starico"></img><img src={star} alt="star" className="starico"></img></Typography>
                                                        </Box>
                                                        <Box className="cancellation d-flex mt-2 mb-3">
                                                            <Tooltip title="Hotel Included" arrow><span className='mr-3'><HotelIcon /></span></Tooltip>
                                                            <Typography>NUBRA (2 Nights) at <b>Mystique Meadows Earth Homes/ Creek Diskit</b> (Room in a cottage)</Typography>
                                                        </Box>
                                                        <Box className="cancellation d-flex mt-2 mb-3">
                                                            <Tooltip title="Hotel Included" arrow><span className='mr-3'><HotelIcon /></span></Tooltip>
                                                            <Typography>PANGONG (1 Nights) at <b>The Nature’s Nest Camps</b> (CAMP)</Typography>
                                                        </Box>
                                                    </div>
                                                </Col>
                                                <Col xs={12} className="consting-list">
                                                    <h5>Costing :</h5>
                                                    <h6>No of Pax and Cars <span className='text-right'>Rate in INR Per Person</span></h6>
                                                    <ul>
                                                        <li className='costing_li'>For 02 Pax with 01 NAC Innova Car <span><b className='text-right'>&#x20B9; 87,400</b></span></li>
                                                        <li className='costing_li'>For 04 Pax with 01 NAC Innova Car  <span><b className='text-right'>&#x20B9; 75,555</b></span></li>
                                                        <li className='costing_li'>For 06 Pax with 02 NAC Innova Car  <span><b className='text-right'>&#x20B9; 79,580</b></span></li>
                                                        <li className='costing_li'>For 06 Pax with 01 NAC Tempo  <span><b className='text-right'>&#x20B9; 73,600</b></span></li>
                                                        <li className='costing_li'>For 08 Pax with 01 NAC Tempo  <span><b className='text-right'>&#x20B9; 71,185</b></span></li>
                                                        <li className='costing_li'>For 10 Pax with 01 NAC Tempo  <span><b className='text-right'>&#x20B9; 69,575</b></span></li>
                                                        <li className='costing_li'>For 12 Pax with 01 NAC Tempoo  <span><b className='text-right'>&#x20B9; 68,655</b></span></li>
                                                        <li className='costing_li'>Extra Bed  <span><b className='text-right'>&#x20B9; 43,700</b></span></li>
                                                        <li className='costing_li'>Child Without Extra Bed  <span><b className='text-right'>&#x20B9; 39,675</b></span></li>

                                                    </ul>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                </TabPanel>
                                <TabPanel value={value} index={3}>

                                    <div className="hoteladdress">
                                        <Row>

                                            <Col xs={12} sm={4}>
                                                <Box className='d-flex act-txt-xs'> <LocationOnIcon className='refreshmentIcon' /> <span>LEH (3 nights)</span></Box>
                                                <Box className='d-flex act-txt-xs'> <LocationOnIcon className='refreshmentIcon' /> <span>NUBRA (2 nights)</span></Box>
                                                <Box className='d-flex act-txt-xs'> <LocationOnIcon className='refreshmentIcon' /> <span>Pangong (1 night)</span></Box>
                                            </Col>

                                        </Row>
                                    </div>

                                </TabPanel>

                            </Col>
                        </Row>
                        <Row className="tabsrow" >
                            <Col xs={12} md={12} className="px-0">
                                <Box className='faq-section'>
                                    <h5>Inclusions And Exclusions</h5>
                                    <div className="hoteladdress pt-3">
                                        <Row>
                                            <Col xs={12} md={6} className="">
                                                <h6 className='text-success'>Your tour package includes -</h6>
                                                <ul className='inclution-list'>
                                                    <li className='font18'><DoneIcon className='refreshmentIcon' />
                                                        <b><span>Round-trip Airfare from Delhi</span></b>
                                                    </li>
                                                    <li><DoneIcon className='refreshmentIcon' />
                                                        <span>Accommodation on a double/ twin sharing basis as per the details given above.</span>
                                                    </li>
                                                    <li><DoneIcon className='refreshmentIcon' />  <span>Meal Plan MPAI as mentioned above.</span></li>
                                                    <li><DoneIcon className='refreshmentIcon' />  <span>Inner line permit.</span></li>
                                                    <li><DoneIcon className='refreshmentIcon' />  <span>GST.</span></li>
                                                    <li><DoneIcon className='refreshmentIcon' />  <span>NAC Innova/ NAC Tempo Traveller as per the itinerary.</span></li>
                                                    <li><DoneIcon className='refreshmentIcon' />  <span>Arrival/departure transfer and all land transfers as per the itinerary.</span></li>
                                                    <li><DoneIcon className='refreshmentIcon' />  <span>Pickup and Drop off from Leh Airport.</span></li>
                                                    <li><DoneIcon className='refreshmentIcon' />  <span>Driver allowance / toll / parking / road tax / fuel charge.</span></li>

                                                </ul>
                                            </Col>
                                            <Col xs={12} md={6} className="">
                                                <h6 className='text-danger'>Your tour package excludes -</h6>
                                                <ul className='exclution-list'>
                                                    <li><ClearIcon className='refreshmentIcon' /> <span>Train Fare and all entry fees at sightseeing spots.</span></li>
                                                    <li><ClearIcon className='refreshmentIcon' /> <span>Monuments entrances fee mentioned in the itinerary.</span></li>
                                                    <li><ClearIcon className='refreshmentIcon' /> <span>Any additional meals other than those mentioned in the 'inclusive' column.</span></li>
                                                    <li><ClearIcon className='refreshmentIcon' /> <span>Travel insurances.</span></li>
                                                    <li><ClearIcon className='refreshmentIcon' /> <span>Items of personal nature like porterage, tips, laundry, telephone calls, mineral water etc.</span></li>
                                                    <li><ClearIcon className='refreshmentIcon' /> <span>Expenses caused by factors beyond our control like rail and flight delays,
                                                        roadblocks, political disturbances, etc.</span></li>
                                                    <li><ClearIcon className='refreshmentIcon' /> <span>Any item not specifically mentioned in the Inclusions column.</span></li>
                                                </ul>
                                            </Col>
                                        </Row>
                                    </div>
                                </Box>
                            </Col>
                        </Row>
                        <Row className="tabsrow activity-tab ">
                            <Col xs={12} sm={12}>
                                <h5>Supplement costs for:</h5>
                                <ul className='inclution-list-simple highlights-section mt-3'>
                                    <li><DoneIcon className='refreshmentIcon mr-2' />  <span>Luncheon by the Indus River <b>@3,000/-</b> per pax.</span></li>
                                    <li><DoneIcon className='refreshmentIcon mr-2' />  <span>Lavish picnic baskets <b>@1,800/-</b> per pax per basket.</span></li>
                                    <li><DoneIcon className='refreshmentIcon mr-2' />  <span>Buddhist talk at monastery (private audience) with Doctorate Scholar Monk - Not chargeable one
                                        can donate some money to the monastery.</span></li>
                                    <li><DoneIcon className='refreshmentIcon mr-2' />  <span>A private polo match session <b>@35,000/-</b> total.</span></li>

                                    <li><DoneIcon className='refreshmentIcon mr-2' />  <span>Sundowner (with cheese and wine) at the sand dunes of Nubra Valley <b>5,000/-</b> per person.  </span></li>
                                    <li><DoneIcon className='refreshmentIcon mr-2' />  <span>Morning/ Evening Yoga session <b>@3,000/-</b> per person.</span></li>
                                    <li><DoneIcon className='refreshmentIcon mr-2' />  <span>Supplement cost for local guide <b>@2,500/-</b> per day..</span></li>
                                    <li><DoneIcon className='refreshmentIcon mr-2' />  <span>Monastery entrances fee <b>@1,800/-</b> per person.</span></li>
                                    <li><DoneIcon className='refreshmentIcon mr-2' />  <span>A/C charges <b>12/- Rs</b> per km (to be directly collected by the driver).</span></li>

                                </ul>
                            </Col>

                        </Row>
                        <Row className="tabsrow activity-tab activity-modal">
                            <Box className="hoteldtlstag px-3">
                                <div className="viewdtlshotelcls">
                                    <Link onClick={() => handleClickOpen()} color="inherit" className="pointercls">
                                        View cancellation policy
                                    </Link>
                                </div>
                                <Dialog
                                    className='cancel-policy-db'
                                    onClose={() => handleClose(null)}
                                    aria-labelledby="customized-dialog-title"
                                    open={open}
                                >
                                    <DialogTitle className="cancel-hd" id="customized-dialog-title" onClose={() => handleClose(null)}>
                                        Cancellation Policy
                                    </DialogTitle>
                                    <DialogContent dividers>

                                        <Box className="formboxguest">
                                            <div className="facility-list cancel-list">
                                                <ul>
                                                    <li className='d-flex'>
                                                        <CheckIcon /> <span>All reservations require a guarantee in the form of a credit card, cash deposit.</span>
                                                    </li>
                                                    <li className='d-flex'>
                                                        <CheckIcon /> <span>Any cancellation requests made after confirming the booking will hold 100% of advance payment and cancellation request made
                                                            will incur the room charges for the entire stay.</span>
                                                    </li>
                                                    <li className='d-flex'>
                                                        <CheckIcon /> <span>There may be a change in the nightly rate if dates of stay are modified.</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </Box>
                                    </DialogContent>
                                </Dialog>

                            </Box>
                        </Row>
                        <Row className="tabsrow activity-tab" >
                            <Box className='faq-section'>
                                <Accordion>
                                    <AccordionSummary
                                        className='accordian-main'
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography><h5>FAQ's</h5></Typography>

                                    </AccordionSummary>
                                    <AccordionDetails id='faqList'>
                                        <div className='faq-section-aacordian'>
                                            <Box className="faq-search-box">
                                                <TextField variant="outlined"
                                                    size="small"
                                                    className="inputboxdetails"
                                                    placeholder="Search here"
                                                    name="search"
                                                    id='faqSearch'
                                                    value={searchText}
                                                    onChange={(e) => handleSearch(e)}
                                                />
                                            </Box>
                                            {faqData.map((item, index) =>
                                                <Accordion expanded={expanded === `panel${item.id}`} onChange={handleChange(`panel${item.id}`)}>
                                                    <MuiAccordionSummaryFAQ aria-controls="panel1d-content" id="panel1d-header">
                                                        <Typography>{item.question}</Typography>
                                                    </MuiAccordionSummaryFAQ>
                                                    <AccordionDetails>
                                                        <Typography>{item.answer}</Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                            )}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>

                            </Box>
                        </Row>
                    </Col>

                    <Col xs={12} sm={12} md={4} lg={4} className="colspace sticky-parent mt-2">
                        <Box className="  sticky-child">
                            {packageDetails?.package?.highlights?.length > 0 ?
                                <>
                                    <Box className='hotelrow mb-3'>
                                        <p className='package-ttl-1'><span className='pull-left'>Highlights</span>
                                            {packageDetails?.package?.highlights?.length > 100 ? <Button onClick={() => setShowMore(!showMore)}>{showMore ? 'show all' : 'Hide'} </Button> : null}

                                        </p>

                                        <Box className='package-ul mb-3'>
                                            {/* <ul className='inclution-list-simple highlights-section'>
                                        <li><DoneIcon className='refreshmentIcon mr-2'/>  <span>Stay In 5 or 4 Star Hotel</span></li>
                                        <li><DoneIcon className='refreshmentIcon mr-2'/>  <span>Break Fast</span></li>
                                        <li><DoneIcon className='refreshmentIcon mr-2'/>  <span>Airport Transfers</span></li>
                                        <li><DoneIcon className='refreshmentIcon mr-2'/>  <span>Golf in Alpine golf club </span></li>
                                        <li><DoneIcon className='refreshmentIcon mr-2'/>  <span>Golf in Navatanee golf club  </span></li>

                                    </ul> */}

                                            <div className={`package-highlights-list-ul-1 ${showMore && 'package-highlights'}`}>
                                                <span className=''>
                                                    {ReactHtmlParser(packageDetails?.package?.highlights)}
                                                </span>
                                            </div>
                                        </Box>
                                    </Box>
                                </> : null
                            }
                            <Box className='hotelrow'>
                                <p className='package-ttl-1'><span className='pull-left'>Travel Date</span> </p>
                                <Box className='mt-1'>
                                    <MuiPickersUtilsProvider className="Dobselector mt-0" spacing={0} utils={DateFnsUtils}>
                                        <KeyboardDatePicker className="date-activity"
                                            open={showDatePicker}
                                            margin="normal"
                                            variant="inline"
                                            inputVariant="outlined"
                                            size="small"
                                            id="date-picker-dialog"
                                            autoOk
                                            format="dd-MMM-yyyy"
                                            value={travelDate}
                                            minDate={"2023/04/16"}
                                            maxDate={"2023/05/31"}
                                            onClose={() => setShowDatePicker(false)}
                                            onChange={(date) => {
                                                setTravelDate(date)
                                                setShowDatePicker(!showDatePicker)
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date'
                                            }}
                                            InputProps={{ readOnly: true, required: true }}
                                            onClick={() => setShowDatePicker(!showDatePicker)}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Box>

                                <Row>
                                    <Col xs={12} md={12} className=''>
                                        <Box className='px-xs-0'>
                                            <p className='package-ttl-1'>Members</p>
                                            <Grid container>
                                                <Grid item xs={8}>
                                                    <p className='tlttravellertype'>Adult</p>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Box className='person-btn-grp'>
                                                        <ButtonGroup
                                                            size="small"
                                                            aria-label="small outlined button group"
                                                            className="btnGroup"
                                                        >
                                                            <Button className="btnGroup" onClick={() => onChangeAdultQuantity("subtract")}>-</Button>
                                                            <Button className="btnGroup">
                                                                {adultQuantity}
                                                            </Button>
                                                            <Button className="btnGroup" onClick={() => onChangeAdultQuantity("add")}>+</Button>
                                                        </ButtonGroup>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Grid container className='mt-2'>
                                                <Grid item xs={8}>
                                                    <p className='tlttravellertype'> {"Child(< 5 years)"}</p>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Box className='person-btn-grp'>
                                                        <ButtonGroup
                                                            size="small"
                                                            aria-label="small outlined button group"
                                                            className="btnGroup"
                                                        >
                                                            <Button className="btnGroup" onClick={() => onChangeChildQuantity("subtract")}>-</Button>
                                                            <Button className="btnGroup">
                                                                {childQuantity}
                                                            </Button>
                                                            <Button className="btnGroup" onClick={() => onChangeChildQuantity("add")}>+</Button>
                                                        </ButtonGroup>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <hr></hr>
                                    </Col>

                                    <Col xs={12} md={12} className=" py-0 package-wrapper">
                                        <Box className='pull-right'>
                                            <Button onClick={handleContinue} variant="contained" color="secondary" size="small" className='btn'>
                                                Continue
                                            </Button>
                                        </Box>
                                    </Col>
                                </Row>
                            </Box>
                        </Box>
                    </Col>
                </Row>
                {/* )} */}
            </Container>
        </div>
        <Modal className='flight-modal' show={modalFlight} onHide={handleModalFlightClose}>
            <Modal.Header>
                <b>Select Flight</b>
                <Button className='float-right text-danger' onClick={handleModalFlightClose}>X</Button>
            </Modal.Header>
            <Modal.Body>
                <FlightModal
                    packageDetails={packageDetails}
                />
            </Modal.Body>
            {/* <Modal.Footer>
            <Button className='btn btn-primary btn-small ml-2' color='success' variant='contained' onClick={handleModalFlightClose}>Update</Button> 
            <Button className='btn btn-danger' color='error' variant='contained' onClick={handleModalFlightClose}>Close</Button>
            </Modal.Footer> */}
        </Modal>
        <Modal className='flight-book-modal' show={modalBook} onHide={handleModalBookClose}>

            <Modal.Body>
                <Box className='text-right'>
                    <Button className='text-danger btn-close' onClick={handleModalBookClose}><HighlightOffIcon /></Button>
                </Box>
                <ActivityBookingModal
                    travelDate={travelDate}
                    totalPax={adultQuantity + childQuantity}
                    selectedPricing={selectedPricing}
                    setModalBook={setModalBook}
                />
            </Modal.Body>

        </Modal>

        <Dialog
        open={openDialog}
        keepMounted
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
      >
       <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Please select any one package
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <NavLink to="/leh-ladakh-package-details?rooms"> */}
            <Button onClick={scroll_click}>Ok</Button>
            {/* </NavLink> */}
        </DialogActions>
      </Dialog>
    </>
}

export default ActivityDetails