import { CircularProgress, Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import smallico from '../../assets/images/hotel.svg'
import './topdeals.css'
import { API_BASE_URL, ADMIN_IMAGE } from '../../utility/apiConstants'
import { getAuth } from '../../utility/apiConfig'
import { useHistory } from 'react-router'
import TopDealsSkltn from './TopDealsSkltn'

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: 15
  },
  media: {
    height: "300px",
    margin: "10px",
    borderRadius: "5px"
  }, 
  para:{
    fontSize: "25px !important",
  }, 
  cityPara:{
    fontSize: "20px !important",
  }
})

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 786 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const HotelTopdeals = () => {
  const history = useHistory()
  const classes = useStyles()
  const [res, setRes] = useState()
  const [Loading, setLoading] = useState(false)
  const [oneWay, setObj] = useState(null)

  const topDeal = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 5000,
    focusOnSelect: false,

    responsive: [
      {
        breakpoint: 1024,
        topDeal: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        topDeal: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        topDeal: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  const handleSearch = (city_name, country_name, origin) => {
    // setLoading(true)
    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)
    const dayAftertomorrow = new Date(tomorrow)
    dayAftertomorrow.setDate(dayAftertomorrow.getDate() + 1)
    const hotelInfo = {
      city: city_name + ' (' + country_name + ')',
      fromCity: {
        category: 'Search Results',
        city_name: city_name,
        count: 0,
        country_name: country_name,
        id: origin,
        label: city_name + ', ' + country_name,
        title: city_name + ' (' + country_name + ')',
        type: 'Search Results'
      },
      cityError: '',
      hotel_destination: origin,
      hotel_checkin: tomorrow,
      inError: '',
      inDate: tomorrow,
      hotel_checkout: dayAftertomorrow,
      outError: '',
      outDate: dayAftertomorrow,
      rating: [],
      nationality: '',
      nationalityValue: '',
      country_of_residence: '',
      conutryValue: '',
      rooms: 1,
      nights: 1,
      nerror: '',
      nextDay: dayAftertomorrow,
      selectedRating: []
    }

    const travellerCount = [{
      adult: [1],
      child: [0],
      childAge: []
    }]

    // let onward = {...oneWay};

    //     onward.to = 'nhghfhgfhg';
    //     onward.depature = stringToDate(tomorrow);

    //     setObj(onward)
    // let onewayVar = {
    //     trip_type: 'oneway',
    //     from: 'Chennai (MAA)',
    //     from_loc_id: '4445',
    //     to: city+' ('+code+')',
    //     to_loc_id: origin,
    //     depature: stringToDate(tomorrow),
    //     adult: 1,
    //     child: 0,
    //     infant: 0,
    //     nonStop: false,
    //     studentFare: false,
    //     search_flight: 'search',
    //     v_class: 'Economy',
    //     carrier: []
    // }

    var currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    var day = currentDate.getDate()
    var dayCheckOut = currentDate.getDate() + 1
    var month = currentDate.getMonth() + 1
    var year = currentDate.getFullYear()
    var checkindate = day + "-" + month + "-" + year;
    var checkoudate = dayCheckOut + "-" + month + "-" + year;

    const finalData = {
      city: city_name + ' (' + country_name + ')',
      hotel_destination: origin,
      hotel_checkin: checkindate,
      hotel_checkout: checkoudate,
      rooms: 1,
      adult: [1],
      child: [0],
      nationality: '',
      rating: []
    }

    if (finalData != '') {
      hotelSearch(finalData, hotelInfo, travellerCount)
    }
  }

  const hotelSearch = (finalData, hotelInfo, travellerCount) => {

    setLoading(false)
    history.push({
      pathname: '/hotel/hotel-search-results',
      state: [finalData, hotelInfo, travellerCount]
    })
  }

  const getTopList = () => {
    setLoading(true)
    try {
      getAuth(API_BASE_URL + 'top_list/fetch_top_list_hotel')
        .then((response) => {
          if (response.data.status) {
            setRes(response.data.data)
          }
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
        })
    } catch (error) {

    }
  }

  useEffect(() => {
    getTopList()
  }, [])

  return (
        <Container className="Slider-deales">
            {Loading ? (<TopDealsSkltn />)
              : <>
              <Box className='slider-box'>
                <Grid container>
                    <Grid items xl={12}><h4 className="topDealLabel">Hotel <span className='topSpanLabel'>Top Deals</span></h4></Grid>
                </Grid>
                <Grid container>
                    <Grid items lg={12} md={12} sm={12} xs={12}>
                      <Carousel responsive={responsive}>
                            {
                              res?.map((r) => (
                                    <>
                                        <div>
                                            <Card className="cardSlider">
                                                {/* <Box className="icon-flight-box">
                                                    <img src={smallico} className="ico-flight"/>
                                                </Box> */}
                                                <CardActionArea>
                                                    <CardMedia
                                                    className={classes.media}
                                                    image={ADMIN_IMAGE + r.image}
                                                    title="fligths views"
                                                    />
                                                      <Typography gutterBottom variant="h4" className="topDealsCity">
                                                        {r.city_name}
                                                        <Typography gutterBottom variant="h6" className={classes.cityPara}>
                                                        {r.country_name}
                                                        </Typography>
                                                      </Typography>
                                                </CardActionArea>
                                                <CardActions className='topHotelDealsButton'>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    onClick={() => handleSearch(r.city_name, r.country_name, r.origin)}
                                                    disabled={Loading}
                                            >{Loading ? (<CircularProgress size={24} color="inherit" />) : 'View Hotel'}</Button>
                                                </CardActions>
                                            </Card>
                                        </div>
                                    </>
                              ))
                            }
                        </Carousel>
                    </Grid>
                </Grid>
              </Box>
            </>
            }
        </Container>
  )
}
export default HotelTopdeals
