import React, { useEffect, useState } from 'react'
import { Box, Button, Select, MenuItem, TextField, TextareaAutosize, FormHelperText, CircularProgress } from '@mui/material'
import { Col, Container, Row, } from 'react-bootstrap'
import '../../flights/flight-booking/flight-summary/flightsummary.css'
import '../activity-details/selectactivity.css'
import '../activity-results/activityresult.css'
import { countrylist } from '../../../services/countryList'
import { emailValidator, fnameVali, lnameVali } from '../../user/validation'
import ActivityBooked from './ActivityBooked'
import Modal from 'react-bootstrap/Modal';
import { authPost } from '../../../utility/apiConfig'
import { API_BASE_URL } from '../../../utility/apiConstants'

const ActivityBookingModal = (props) => {
    const userDetails = localStorage.getItem('login_data')
    const [modalPreview, setModalPreview] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [details, setDetails] = useState({
        'title': null, 'first_name': null,
        'last_name': null, 'country_code': 'India (+91)',
        'phone': null, 'email': null,
        'address': null, 'agency_name': null,
        'remarks': null
    })
    const [error, setError] = useState({
        'title': null, 'first_name': null,
        'last_name': null, 'country_code': null,
        'phone': null, 'email': null,
        'address': null
    })

    useEffect(() => {
        let scripts = 'https://unpkg.com/libphonenumber-js/bundle/libphonenumber-js.min.js'
        var script = document.createElement("script");
        script.src = scripts
        script.async = true;
        document.body.appendChild(script);
    }, [])

    useEffect(() => {
        if (userDetails !== null) {
            let user = JSON.parse(userDetails).data;
            setDetails({
                ...details,
                title: user.title === "1" ? "Mr" : "Ms",
                first_name: user.first_name,
                last_name: user.last_name,
                country_code: user.country_code,
                phone: user.phone,
                email: user.email,
                address: user.address,
                agency_name: user.agency_name
            })
        }
    }, [])

    const handleDetails = event => {
        error[event.target.name] = null
        setDetails({ ...details, [event.target.name]: event.target.value })
    };

    const handlePhoneChange = (e) => {
        setError({ ...error, phone: null });
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        if (onlyNums.length < 10) {
            setDetails({ ...details, phone: onlyNums })
        } else if (onlyNums.length === 10) {
            const number = onlyNums;
            setDetails({ ...details, phone: number })
        }
    }

    function isValidNumber(number) {
        return new window.libphonenumber.parsePhoneNumber(number).isValid()
    }
    const handleModalPreviewOpen = () => setModalPreview(true);
    const handleModalPreviewClose = () => setModalPreview(false);

    const checkValidation = () => {
        var number = details.phone;
        var country = details.country_code;
        var phoneNumber = country + " " + number;

        let titleErr = details.title === null ? "Please choose title" : null;
        let firstNameErr = fnameVali(details.first_name);
        let lastNameErr = lnameVali(details.last_name);
        let countryCode = details.country_code === null ? "Please enter country code" : null;
        // let gst = gstJstNumVali(companyDetails.gstNum);
        let phone = details.phone === null ? "Please enter phone number" : phoneNumber.length <= 6 ? "Too short" : isValidNumber(phoneNumber) ? null : "Please enter a valid phone number.";
        let email = emailValidator(details.email);
        let addressErr = details.address === null ? "Please enter address" : null
        if (titleErr || countryCode || phone || email || firstNameErr || lastNameErr || addressErr) {
            setError({
                ...error,
                title: titleErr,
                first_name: firstNameErr,
                last_name: lastNameErr,
                country_code: countryCode,
                phone: phone,
                email: email,
                address: addressErr
            })
            return;
        }
        else {
            setIsLoading(true);
            let dataToSend = {
                ...details,
                "travelDate": props.travelDate,
                "totalPax": props.totalPax,
                "selectedPackage": props.selectedPricing
            }
            // console.log("dataToSend", dataToSend)
            try {
                authPost(API_BASE_URL + 'management/submit_package_details', dataToSend)
                    .then((response) => {
                        if (response.data.status) {
                            setIsLoading(false);
                            props.setModalBook(false);
                            handleModalPreviewOpen();
                        }
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        window.alert("Something went wrong. Please try after sometime.")
                    })
            } catch (error) {

            }

        }
    }

    return <>
        <Container className='selectactivity-container'>

            <Box className="travellerdetailsBox">

                <Row className="inputFieldBox mt-2">
                    <Col xs={12} className="mb-2"><div class="three"><h1>Contact Details </h1></div></Col>
                    <Col xs={12} sm={12} md={2}>
                        <Box className="tlt-input-traveller">Title <span className='text-danger'> *</span></Box>
                        <Box className="setinputBox">
                            <Select
                                className="dropdownssrinput"
                                id="standard-select-title"
                                variant="outlined"
                                size="small"
                                value={details.title}
                                name='title'
                                onChange={(e) => {
                                    handleDetails(e)
                                }}
                                error={error.title}
                                helperText={error.title}
                                disabled={userDetails !== null ? true : false}
                            >
                                <MenuItem value="Mr">Mr.</MenuItem>
                                <MenuItem value="Ms">Ms.</MenuItem>
                            </Select>
                            {error.title !== null && (<FormHelperText >{error.title}</FormHelperText>)}
                        </Box>
                    </Col>
                    <Col xs={12} sm={12} md={4}>
                        <Box className="tlt-input-traveller">First Name <span className='text-danger'> *</span></Box>
                        <Box className="setinputBox">
                            <TextField variant="outlined"
                                size="small"
                                className="inputboxdetails"
                                placeholder="First Name"
                                name="first_name"
                                value={details.first_name}
                                onChange={(e) => {
                                    handleDetails(e)
                                }}
                                error={!!error.first_name}
                                helperText={error.first_name}
                                disabled={userDetails !== null ? true : false}
                            />
                        </Box>
                    </Col>
                    <Col xs={12} sm={12} md={6}>
                        <Box className="tlt-input-traveller">Last Name <span className='text-danger'> *</span></Box>
                        <Box className="setinputBox">
                            <TextField variant="outlined"
                                size="small"
                                className="inputboxdetails"
                                placeholder="Last Name"
                                name="last_name"
                                value={details.last_name}
                                onChange={(e) => {
                                    handleDetails(e)
                                }}
                                error={!!error.last_name}
                                helperText={error.last_name}
                                disabled={userDetails !== null ? true : false}
                            />
                        </Box>
                    </Col>

                    <Col xs={12} sm={12} md={6}>
                        <Box className="tlt-input-traveller mt-3">Agency Name</Box>
                        <Box className="setinputBox">
                            <TextField variant="outlined"
                                size="small"
                                className="inputboxdetails"
                                placeholder="Agency Name"
                                name="agnecy_name"
                                value={details.agency_name}
                                onChange={(e) => {
                                    handleDetails(e)
                                }}
                                error={!!error.agency_name}
                                helperText={error.agency_name}
                                disabled={userDetails !== null ? true : false}
                            />
                        </Box>
                    </Col>
                </Row>

                <Row className="inputFieldBox mt-3">
                    <Col xs={12} sm={12} md={6}>
                        <Box className="setinputBox">
                            <Box className="tlt-input-traveller mb-1">Mobile Number <span className='text-danger'> *</span></Box>
                            <Box className="flexBoxdiv">
                                <Select
                                    className="countrycode"
                                    id="standard-select-title"
                                    variant="outlined"
                                    size="small"
                                    name="country_code"
                                    value={details.country_code}
                                    onChange={handleDetails}
                                    displayEmpty
                                    disabled={userDetails !== null ? true : false}
                                >
                                    <MenuItem value={"India (+91)"}>India (+91)</MenuItem>
                                    {countrylist.map(option => (
                                        <MenuItem key={option.origin} value={option.country_code}>
                                            {option.name + " (" + option.country_code + ")"}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {error.country_code !== null && (<FormHelperText >{error.country_code}</FormHelperText>)}
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    className="inputboxdetails"
                                    placeholder="Mobile Number"
                                    name="phone"
                                    value={details.phone}
                                    error={error.phone}
                                    // helperText={error.phone}
                                    onChange={handlePhoneChange}
                                    disabled={userDetails !== null ? true : false}
                                />
                            </Box>
                            {error.phone !== null && (<FormHelperText>{error.phone}</FormHelperText>)}

                        </Box>
                    </Col>
                    <Col xs={12} sm={12} md={6}>
                        <Box className="setinputBox ">
                            <Box className="tlt-input-traveller">Email Address <span className='text-danger'> *</span></Box>
                            <TextField variant="outlined"
                                size="small"
                                className="inputboxdetails"
                                placeholder="Email Address"
                                name="email"
                                value={details.email}
                                onChange={(e) => {
                                    handleDetails(e)
                                }}
                                error={!!error.email}
                                helperText={error.email}
                                disabled={userDetails !== null ? true : false}
                            />
                        </Box>
                    </Col>

                    <Col xs={12} sm={12} md={12}>
                        <Box className="setinputBox mt-3">
                            <Box className="tlt-input-traveller">Remarks</Box>
                            <TextareaAutosize
                                aria-label="minimum height"
                                minRows={3}
                                className="inputboxdetails mt-1"
                                placeholder="Remarks"
                                name="remarks"
                                value={details.remarks}
                                onChange={(e) => {
                                    handleDetails(e)
                                }}
                                error={!!error.remarks}
                                helperText={error.remarks}
                            />

                        </Box>
                    </Col>
                    <Col xs={12} sm={12} md={12} className="text-right">
                        <Box className="reviewbtncontent  mt-3">
                            <Button
                                // onClick={handleModalPreviewOpen}
                                onClick={() => checkValidation()}
                                variant="contained"
                                color="success"
                                className='btn btn-success'>
                                {isLoading ? (<CircularProgress size={24} color="inherit" />) : 'Submit'}
                            </Button>
                        </Box>
                    </Col>

                </Row>
            </Box>

        </Container>

        <Modal className='preview-modal' show={modalPreview} onHide={handleModalPreviewClose}>
            <Modal.Body>
                <ActivityBooked />
            </Modal.Body>
        </Modal>

    </>
}

export default ActivityBookingModal
