import { authPost } from '../../../utility/apiConfig'
import { API_BASE_URL } from '../../../utility/apiConstants'

export const SSR_LOADING = 'LOADING'
export const SSR_REQUEST = 'SSR_REQUEST'
export const SSR_SUCCESS = 'SSR_SUCCESS'
export const SSR_ERROR = 'SSR_ERROR'

export const SSR_ROUND_LOADING = 'SSR_ROUND_LOADING'
export const SSR_ROUND_REQUEST = 'SSR_ROUND_REQUEST'
export const SSR_ROUND_SUCCESS = 'SSR_ROUND_SUCCESS'
export const SSR_ROUND_ERROR = 'SSR_ROUND_ERROR'

export const SSR_MULTI_LOADING = 'SSR_MULTI_LOADING'
export const SSR_MULTI_REQUEST = 'SSR_MULTI_REQUEST'
export const SSR_MULTI_SUCCESS = 'SSR_MULTI_SUCCESS'
export const SSR_MULTI_ERROR = 'SSR_MULTI_ERROR'

export const ssrRequest = (data) => {
  return {
    type: SSR_REQUEST,
    payload: data
  }
}

const ssrSuccess = (data) => {
  return {
    type: SSR_SUCCESS,
    payload: data
  }
}

const ssrError = (data) => {
  return {
    type: SSR_ERROR,
    payload: data
  }
}

export const ssrRoundRequest = (data) => {
  return {
    type: SSR_ROUND_REQUEST,
    payload: data
  }
}


export const ssrMultiRequest = (data) => {
  return {
    type: SSR_MULTI_REQUEST,
    payload: data
  }
}

export const flightBookingSSRrequest = (data, sid, history) => {
  return (dispatch) => {
    try {
      console.log("ssrData" ,data)
      authPost(API_BASE_URL + 'flight/booking/' + sid, data)
        .then((response) => {
          if (response.data.status) {
            dispatch(ssrSuccess(response.data.data))
          } else {
            history.push({
              pathname: '/flight/failed',
              state: response.data.message
            })

            dispatch(ssrError(response.data.data.message))
          }
        }).catch(() => {
          dispatch(ssrError('error'))
        })
    } catch (error) {
      dispatch(ssrError('error'))
    }
  }
}
