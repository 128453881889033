import { PRICE_RANGE, AIRLINE, DEPATURE, FARE_IDENTIFIRE, SHOW_NET, ARRIVAL, STOPS, FLIGHT_DETA, AIRLINE_RESET } from '../actions/flightFilterActions'
const filterIniState = {
  priceMinRange: 0,
  priceMaxRange: 0,
  showNet: false,
  stops: null,
  arrivalTime: null,
  depatureTime: null,
  fareIdentifire: null,
  airline: null,
  filterdFlightData: null
}
export const flightFilterReducers = (state = filterIniState, { type, payload, flightData }) => {
  switch (type) {
    case FLIGHT_DETA:
      return {
        ...state,
        filterdFlightData: flightData
      }
    case PRICE_RANGE:
      return {
        ...state,
        priceMinRange: payload[0],
        priceMaxRange: payload[1],
        filterdFlightData: flightData
      }
    case SHOW_NET:
      return {
        ...state,
        showNet: payload,
        filterdFlightData: flightData
      }
    case STOPS:
      return {
        ...state,
        stops: payload,
        filterdFlightData: flightData
      }
    case DEPATURE:
      return {
        ...state,
        depatureTime: payload,
        filterdFlightData: flightData
      }
    case ARRIVAL:
      return {
        ...state,
        arrivalTime: payload,
        filterdFlightData: flightData
      }
    case FARE_IDENTIFIRE:
      return {
        ...state,
        fareIdentifire: payload,
        filterdFlightData: flightData
      }
    case AIRLINE:
      return {
        ...state,
        airline: payload,
        filterdFlightData: flightData
      }
    case AIRLINE_RESET:
      return {
        priceMinRange: 0,
        priceMaxRange: 0,
        showNet: false,
        stops: null,
        arrivalTime: null,
        depatureTime: null,
        fareIdentifire: null,
        airline: null,
        filterdFlightData: null
      }
    default:
      return state
  }
}
