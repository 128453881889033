export const emailValidator = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    if (!email || email.length <= 0) {
        return 'Email cannot be empty.';
    }
    if (!re.test(email)) {
        return 'Ooops! We need a valid email address.';
    }
    return '';
};

export const passwordValidator = (password: string) => {
    const re = /^(?=(?:[^A-Z]*[A-Z]){1,}(?![^A-Z]*[A-Z]))(?=(?:[^0-9]*[0-9]){2,}(?![^0-9]*[0-9]))(?=(?:[^a-z]*[a-z]){1,}(?![^a-z]*[a-z]))[A-Za-z0-9][^'=-]{8,}$/;
    if (!password || password.length <= 0) {
        return 'Password cannot be empty.';
    }

    if (!re.test(password)) {
        return "Password must be atleast 8 characters long, must contain atleast one uppercase and one lowercase characters.It must not contain these characters (=,-,').Also, it must contain atleast 2 numeric characters.";
    }

    return '';
};


export const confirmPassVali = (confirmPasswaord, password) => {

    if (!confirmPasswaord || confirmPasswaord.length <= 0) {
        return 'Confirmation password cannot be empty.';
    }

    if (confirmPasswaord != password) {
        return 'Confirmation password does not match!';
    }

    return '';
};

export const PassVali = (password: string) => {

    if (!password || password.length <= 0) {
        return 'Password cannot be empty.';
    }

    return '';
};


export const fnameVali = (name: String) => {
    if (!name || name.length <= 0) {
        return 'First name cannot be empty.';
    }

    if (!/^[a-zA-Z ]*$/g.test(name)) {
        return 'Only alphabets are allowed';
      }
    return '';
}

export const lnameVali = (name: String) => {
    if (!name || name.length <= 0) {
        return 'Last name cannot be empty.';
    }

    if (!/^[a-zA-Z ]*$/g.test(name)) {
        return 'Only alphabets are allowed';
      }
    return '';
}

export const phVali = (num: Number) => {

    if (!num || num.length <= 0) {
        return 'Phone number cannot be empty.';
    }

    if (!Number(num)) {
        return 'Please enter valid phone numbers';
    }

    if (!num || num.length < 6) {
        return 'Office phone number must be 10 digit.';
    }

    return '';
}

export const ofPhVali = (num: Number) => {

    if (!num || num.length <= 0) {
        return 'Office phone number cannot be empty.';
    }

    if (!Number(num)) {
        return 'Please enter valid phone numbers';
    }

    if (!num || num.length <= 6) {
        return 'Office phone number must be 6 digit.';
    }

    return '';
}


export const pcodeVali = (name: String) => {

    if (!name || name.length <= 0) {
        return 'Please provide the pin code';
    }

    if (!Number(name)) {
        return 'Please enter valid pin code';
    }

    return '';
}

export const areacodeVali = (name: String) => {

    if (!name || name.length <= 0) {
        return 'Please provide the area code';
    }

    if (!Number(name)) {
        return 'Please enter valid area code';
    }

    return '';
}

export const countryVali = (name: String) => {

    if (!name || name.length <= 0) {
        return 'please select country';
    }
    return '';
}

export const cityVali = (name: String) => {
    if (!name || name.length <= 0) {
        return 'Please select city';
    }
    return '';
}

export const stateVali = (name: String) => {
    if (!name || name.length <= 0) {
        return 'Please select state';
    }
    return '';
}

export const addrVali = (name: String) => {
    if (!name || name.length <= 0) {
        return 'Please provide the address';
    }
    return '';
}

export const compVali = (name: String) => {
    if (!name || name.length <= 0) {
        return 'Company name cannot be empty.';
    }
    return '';
}


export const panVali = (name: String) => {

    const regex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

    if (!name || name.length <= 0) {
        return 'PAN number cannot be empty.';
    }

    if (!regex.test(name)) {
        return 'Please enter valid PAN number';
    }
    return '';
}


export const panHolderVali = (name: String) => {
    if (!name || name.length <= 0) {
        return 'PAN holder name cannot be empty.';
    }
    return '';
}

export const gstCodeVali = (name: String) => {
    if (!name || name.length <= 0) {
        return 'GST State Code cannot be empty.';
    }
    return '';
}
export const gstJstNumVali = (name: String) => {
    const regex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9]{1}[Z]{1}[A-Z\d]{1}$/;
    if (!name || name.length <= 0) {
        return 'GST Number cannot be empty.';
    }

    else if (!regex.test(name)) {
        return 'Please enter valid GST number';
    }

    else return '';

}

export const gstNumVali = (name, stateCode, panNum) => {
    var str = name;
    var gtno = stateCode + '' + panNum;

    if (gtno) {
        var index = str.search(gtno);
        if (index !== -1) {
            return ''
        } else {
            return 'Please enter valid GST number';
        }
    }
    return '';
}

export const gstFileVali = (name: String) => {
    if (!name || name.length <= 0) {
        return 'Please upload valid GST file';
    }
    return '';
}


export const addrProofVali = (name: String) => {
    if (!name || name.length <= 0) {
        return 'Please upload valid address proof';
    }
    return '';
}

export const cancelledChequeValid = (name: String) => {
    if (!name || name.length <= 0) {
        return 'Please upload valid Cancelled Cheque';
    }
    return '';
}

export const pancardValid = (name: String) => {
    if (!name || name.length <= 0) {
        return 'Please upload valid Pan Card';
    }
    return '';
}

export const checkEmpty = (name: String) => {
    if (!name || name.length <= 0) {
        return 'Please enter valid ' + name;
    }
    return '';
}

export const address = (name:String) =>{
    if (!name || name.length <= 0) {
        return 'Address field is mandatory';
    }
    return '';
    }


export const selectVali = (name: String, error:String) => { 
    if (!name || name.length <= 0) {
        return error;
    }
    return '';
}

export const razorpayAmountVal = (name: String, error:String) => { 
    const regex = /^[1-9]\d*(,\d+)?$/;
    if (!regex.test(name)) {
        return error;
    }

    if (!name || name.length <= 0) {
        return error;
    }
    return '';
}

export const stdValidator = (value) => { 
if (!value || value.length <= 0) {
    return `STD Code cannot be empty`;
}
else if ((value.length < 2) || (value.length > 6)) {
   return 'Invalid STD Code';
}
else {
    return null;
}
}