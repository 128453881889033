import React from 'react'
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { Button, Col, Container, Row } from 'react-bootstrap'
import img from '../../../assets/images/halfsemi2.jpg'


var Images =[
	{
		"url": "https://itwgo.in/b2btravelagentAPI/extras/custom/TMX1512291534825461/images/leh/shanti-stupta-leh1.jpg"
	},
	{
		"url": "https://itwgo.in/b2btravelagentAPI/extras/custom/TMX1512291534825461/images/leh/Leh_Palace_2011.jpg"
	},
	{
		"url": "https://itwgo.in/b2btravelagentAPI/extras/custom/TMX1512291534825461/images/leh/hall-of-fame1.jpg"
	},
	{
		"url": "https://itwgo.in/b2btravelagentAPI/extras/custom/TMX1512291534825461/images/leh/download (14).jpeg"
	},
	{
		"url": "https://itwgo.in/b2btravelagentAPI/extras/custom/TMX1512291534825461/images/leh/download (13).jpeg"
	},
	{
		"url": "https://itwgo.in/b2btravelagentAPI/extras/custom/TMX1512291534825461/images/leh/image-6.jpg"
	},
	{
		"url": "https://itwgo.in/b2btravelagentAPI/extras/custom/TMX1512291534825461/images/leh/Magnetic-Hill-in-Leh-A-Zero-Gravity-Place-of-India.webp"
	},
	{
		"url": "https://itwgo.in/b2btravelagentAPI/extras/custom/TMX1512291534825461/images/leh/pathar-sahib5.jpg"
	},
	{
		"url": "https://itwgo.in/b2btravelagentAPI/extras/custom/TMX1512291534825461/images/leh/Sangam-point-Leh-Ladakh-1118x530.jpg"
	},
	{
		"url": "https://itwgo.in/b2btravelagentAPI/extras/custom/TMX1512291534825461/images/leh/truth-behind-the-mysterious-magnetic-hill-of-ladakh.jpg"
	},
	{
		"url": "https://itwgo.in/b2btravelagentAPI/extras/custom/TMX1512291534825461/images/leh/Turtuk-Travel-Guide-10.jpg"
	},
]
const ActivityPopupImg = (props) => {
  
  let image_url = Images.map(x=> {return (
    {original: x.url,
    thumbnail: x.url}
  )})

  return (
        <Container>
            <Row className="sliderBox w-100 justify-content-center">
              {image_url && image_url?.length > 0 &&(
              <ImageGallery 
              onThumbnailClick={true}
              items={image_url} />
              )} 
            </Row>
        </Container>
   
  );
}

export default ActivityPopupImg
